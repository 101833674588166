<script>
import { retentionMixins } from "../../../mixins/retention.js";
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [notificationMixins, retentionMixins],
  emits: [ "modified" ],
  props: {
    retentionSchedule: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      updatedCategory: this.retentionSchedule.category,
      updatedTitle: this.retentionSchedule.title,
      updatedPeriod: this.retentionSchedule.period,
      updatedRegion: this.retentionSchedule.region,
    };
  },
  asyncComputed: {
  },
  methods: {
    getTitle() {
      return `${this.retentionSchedule.id} - ${this.retentionSchedule.title}`;
    },
    getUpdatedTitle() {
      return `${this.retentionSchedule.id} - ${this.updatedTitle}`;
    },
    async editScheduleConfirmed() {
      await this.updateRetentionSchedule({
        id: this.retentionSchedule.id,
        category: this.updatedCategory,
        title: this.updatedTitle,
        period: this.updatedPeriod,
        region: this.updatedRegion,
      });
      this.showSuccess(this.$t("retention-schedule-updated", { item: this.getUpdatedTitle() }));
      this.$emit("modified");
      this.$emit("closed", true);
    }
  },
};
</script>

<template>
  <Popup :valid="updatedCategory.length >= 2 && updatedTitle.length >= 2" @confirmed="editScheduleConfirmed" @cancelled="$emit('closed', false)">
    <template v-slot:header>{{ $t("retention-schedule-edit-title", { item: getTitle() }) }}</template>
      <template v-slot:body>
        <m-text-field v-model="updatedCategory" outlined required>
          <m-floating-label>{{ $t("retention-schedule-edit-category-placeholder") }}</m-floating-label>
        </m-text-field>
        <br/>
        <m-text-field v-model="updatedTitle" outlined required>
          <m-floating-label>{{ $t("retention-schedule-edit-title-placeholder") }}</m-floating-label>
        </m-text-field>
        <br/>
        <m-text-field v-model="updatedPeriod" outlined required>
          <m-floating-label>{{ $t("retention-schedule-edit-period-placeholder") }}</m-floating-label>
        </m-text-field>
        <br/>
        <m-select v-model="updatedRegion" outlined required>
          <option value="default" selected>Default Region</option>
          <option value="svk">SVK Region</option>
        </m-select>
      </template>
  </Popup>
</template>

<style lang="scss" scoped>
  .message {
    margin-bottom: 1.5em;
  }
  .mdc-select {
  margin-top: 12px;
}
</style>