<script>
import { apiMixins } from "../../../mixins/api";
import { notificationMixins } from "../../../mixins/notification";
import { expiryActions } from "../../../mixins/expiry";
import ExpiredItem from "./ExpiredItem.vue";
import ExpiredItemRoot from "./ExpiredItemRoot.vue";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [apiMixins, expiryActions, notificationMixins],
  components: {
    ExpiredItem,
    ExpiredItemRoot,
    Popup,
  },
  props: ["folderPath"],
  data() {
    return {
      rootFolderItem: null,
      numberOfDocuments: 0,
      childFolder: null,
      loading: true,
      availableRetentionSchedules: [],
      useCache: true,
      showAdditionalActionsDialog: false,
      showConfirmAdditionalActionDialog: false,
      showSummaryDialog: false,
      currentAction: null,
      newRetentionSchedule: null,
      pickerEnhanced: true,
      updating: false,
      actionResponse: null,
    };
  },
  asyncComputed: {
    async searchResults() {
      return await this.getExpiredItems({
        query: { folderPath: this.folderPath },
        showLoader: false,
        loading: true,
      }).then((response) => {
        this.loading = false;
        this.rootFolderItem =
          response.documentForPath && response.documentForPath.id
            ? response.documentForPath
            : null;
        this.numberOfDocuments = response.totalItemCount;
        this.updateBreadcrumbs();
        return response;
      });
    },
  },
  mounted() {
    this.loadRetentionSchedules(false);
  },
  methods: {
    async updateBreadcrumbs() {
      const breadcrumbs = this.$store.state.crumbs;
      const initialCrumb =
        breadcrumbs && breadcrumbs.length > 0
          ? breadcrumbs[0]
          : { to: { name: "expired-items" }, label: this.$t("expired-items") };
      var path = this.folderPath.split("||");
      var subFolders = [];
      var currentPath = "";
      path.forEach((item) => {
        if (currentPath === "") {
          currentPath = item;
        } else {
          currentPath += "||" + item;
        }
        subFolders.push({ folderPath: currentPath, folder: item });
      });
      await this.$store.dispatch("setBreadcrumbs", [
        initialCrumb,
        ...subFolders.map((subFolder) => ({
          to: {
            name: "expired-items",
            params: { folderPath: subFolder.folderPath },
          },
          label: subFolder.folder,
        })),
      ]);
    },
    refresh() {
      this.$asyncComputed.searchResults.update();
    },
    navigate(item) {
      this.parentFolderPath = this.folderPath;
      this.$router.push({
        name: "expired-items",
        params: { folderPath: item.folderPath },
      });
    },
    async loadRetentionSchedules() {
      return this.callAPI("item/retention-schedules", {
        showLoader: false,
      }).then((response) => {
        this.availableRetentionSchedules = response.retentionSchedules;
      });
    },
    sort(s) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      this.currentSort = s;
    },
    async downloadConfirmed() {
      this.isDownloading = false;
      let results = await this.downloadFolder(this.item);
      this.downloadFolderResults = results.downloadFolder;
    },
    downloadQueueClosed() {
      this.isDownloaded = false;
      this.isDownloading = false;
      this.downloadFolderResults = null;
    },
    actionSelected(event) {
      this.currentAction = event.target.value;
    },
    showConfirmActionDialog() {
      this.showActions = false;
      this.showConfirmAction = true;
    },
    actionHandler(action) {
      this.currentAction = action;
      this.performAction();
    },
    childActionHandler(action, item) {
      this.numberOfDocuments = item.numberOfDocuments;
      this.childFolder = item;
      this.currentAction = action;
      this.performAction();
    },
    performAction() {
      switch (this.currentAction) {
        case "open":
          this.openFolderAction();
          break;
        case "download":
          this.downloadAction();
          break;
        case "additional-actions":
          this.showAdditionalActions();
          break;
        case "refresh":
          this.refreshExpiredDocumentsAction();
          break;
        default:
          console.log("unknown action", this.currentAction);
      }
    },
    async openFolderAction() {
      this.$store.dispatch("setBreadcrumbs", [
        { to: { name: "expired-items" }, label: this.$t("expired-items") },
      ]);
      let id = this.rootFolderItem.id;
      if (this.childFolder) {
        await this.getIdForFolderPath(this.childFolder.path).then(
          (response) => (id = response)
        );
      }
      this.$router.push({
        name: "browse",
        params: { id },
      });
    },
    downloadAction() {
      this.isDownloading = true;
    },
    showAdditionalActions() {
      this.showAdditionalActionsDialog = true;
      this.showConfirmAdditionalActionDialog = false;
      this.newRetentionSchedule = null;
    },
    confirmAdditionalAction() {
      this.updating = true;
      switch (this.currentAction) {
        case "delete":
          this.deleteAction();
          break;
        case "renew":
          this.renewAction();
          break;
        case "change-retention":
          this.changeRetentionAction();
          break;
        case "save-historic":
          this.saveHistoricAction();
          break;
        default:
          this.updating = false;
          console.log("unknown action", this.currentAction);
      }
    },
    cancelAdditionalActionsDialog() {
      this.numberOfDocuments = this.searchResults.totalItemCount;
      this.currentAction = null;
      this.childFolder = null;
      this.newRetentionSchedule = null;
      this.hideAdditionalActions();
    },
    hideAdditionalActions() {
      this.showAdditionalActionsDialog = false;
      this.showConfirmAdditionalActionDialog = false;
      this.updating = false;
    },
    showSummary() {
      this.showSummaryDialog = true;
    },
    hideSummary() {
      this.showSummaryDialog = false;
    },
    async deleteAction() {
      this.deleteExpiredItems(
        this.childFolder ? this.childFolder.path : this.rootFolderItem.full_path
      ).then((response) => this.actionCompleteHandler(response));
    },
    async renewAction() {
      this.renewExpiredItems(
        this.childFolder ? this.childFolder.path : this.rootFolderItem.full_path
      ).then((response) => this.actionCompleteHandler(response));
    },
    async changeRetentionAction() {
      this.changeRetentionForExpiredItems(
        this.childFolder ? this.childFolder.path : this.rootFolderItem.full_path,
        this.newRetentionSchedule
      ).then((response) => this.actionCompleteHandler(response));
    },
    async saveHistoricAction() {
      this.retainExpiredItemsForHistoricReasons(
        this.childFolder ? this.childFolder.path : this.rootFolderItem.full_path
      ).then((response) => this.actionCompleteHandler(response));
    },
    async refreshExpiredDocumentsAction() {
      this.updating = true;
      this.refreshExpiredDocuments().then((response) => this.actionCompleteHandler(response));
    },
    actionCompleteHandler(response) {
      this.actionResponse = response;
      this.$emit("modified");
      this.hideAdditionalActions();
      this.showSummary();
      this.refresh();
    },
    retentionScheduleChanged(event) {
      this.newRetentionSchedule = event.target.value;
    },
    hasMultipleRetentionSchedules() {
      var schedules = this.searchResults.documentsBySchedule;
      return schedules && Object.keys(schedules).length > 1;
    },
    hasResults() {
      return (
        !this.loading &&
        this.searchResults &&
        this.searchResults.documents &&
        this.searchResults.documents.length !== 0
      );
    },
    isAdditionalActionValid() {
      if (this.updating) {
        return false;
      }
      if (!this.currentAction || this.currentAction === "additional-actions") {
        return false;
      }
      if (
        this.currentAction === "change-retention" &&
        !this.newRetentionSchedule
      ) {
        return false;
      }
      return true;
    },
  },
  filters: {
    formatTimeTaken(milliseconds) {
      return `${(milliseconds * 1.0) / 1000}s`;
    },
    formatScheduleTitle(title) {
      const maxLength = 50;
      if (title.length > maxLength) {
        return title.slice(0, maxLength) + "...";
      } else {
        return title;
      }
    },
    formatPath(path) {
      return path.replaceAll("||", " > ");
    },
    formatNumber(value) {
      return Number(value);
    },
  },
};
</script>

<template>
  <div class="expired-items">
    <div v-if="this.loading" class="loading-message">
      {{ $t("expired-items-loading") }}
    </div>
    <div v-if="!this.loading && !hasResults()" class="empty-message">
      {{ $t("expired-items-empty", { query: this.folderPath }) }}
    </div>
    <div v-if="hasResults()" class="expired-items">
      <portal to="actions">
        <button
          @click.stop="actionHandler('open')"
          class="primary"
          title="Open Folder for Review"
          v-if="this.rootFolderItem"
        >
          <m-icon class="material-icons-outlined" icon="folder_open"></m-icon>
        </button>
        <button
          @click.stop="actionHandler('download')"
          title="Download Folder for Offline Review"
          v-if="this.rootFolderItem"
        >
          <m-icon class="material-icons-outlined" icon="file_download"></m-icon>
        </button>
        <button
          @click.stop="actionHandler('additional-actions')"
          title="View Available Actions"
          v-if="this.rootFolderItem"
        >
          <m-icon class="material-icons-outlined" icon="menu"></m-icon>
        </button>
        <button
          @click.stop="actionHandler('refresh')"
          title="Refresh Expired Documents"
          v-if="!this.rootFolderItem"
        >
          <m-icon class="material-icons-outlined" icon="event_repeat"></m-icon>
        </button>
      </portal>
      <table class="listing">
        <tbody>
          <tr>
            <td>
              <div class="main" v-if="this.rootFolderItem">
                <div class="icon" title="Up a level">
                  <m-icon
                    icon="arrow_back"
                    v-on:click="navigate({ folderPath: rootFolderItem.path })"
                  />
                </div>
                <div>
                 <div v-if="searchResults.totalItemCount > 10000" class="warning">
                    {{ searchResults.totalItemCount.toLocaleString() }} expired documents
                  in this folder and any subfolders.<br/>Due to the large number of expired documents, operations may be slow.
                  </div>
                  <div v-else class="not-a-warning">
                    {{ searchResults.totalItemCount.toLocaleString() }} expired documents in this folder and any subfolders
                  </div>
                </div>
              </div>
              <div class="main" v-else>
                <div class="icon" title="All Repositories">
                  <m-icon icon="home" />
                </div>
                <div>
                  <div v-if="searchResults.totalItemCount > 10000" class="warning">
                    {{ searchResults.totalItemCount.toLocaleString() }} expired documents
                  in all repositories.<br/>Due to the large number of expired documents, operations may be slow.
                  </div>
                  <div v-else class="not-a-warning">
                    {{ searchResults.totalItemCount.toLocaleString() }} expired documents
                  in all repositories
                  </div>
                </div>
              </div>
              <Popup
                v-if="showAdditionalActionsDialog"
                @confirmed="confirmAdditionalAction"
                @cancelled="cancelAdditionalActionsDialog"
                :allowCancel="!updating"
                :valid="isAdditionalActionValid()"
              >
                <template v-slot:header>Available Actions</template>
                <template v-slot:body>
                  <div class="available_actions">
                    <div>
                      <input
                        id="deleteRadio"
                        type="radio"
                        name="action"
                        value="delete"
                        @click="actionSelected"
                      />
                    </div>
                    <div>
                      <label for="deleteRadio">{{ $t("expired-document-delete-action") }}</label>
                      <div
                        v-if="currentAction == 'delete'"
                        class="available_action_additional"
                      >
                        <p>
                          {{ numberOfDocuments.toLocaleString() }} expired
                          documents in this folder (and any subfolders):
                        </p>
                        <p>
                          <strong>{{
                            childFolder
                              ? childFolder.path
                              : rootFolderItem.full_path | formatPath
                          }}</strong>
                        </p>
                        <p>These documents will be deleted permanently.</p>
                      </div>
                    </div>
                    <div>
                      <input
                        id="renewRadio"
                        type="radio"
                        name="action"
                        value="renew"
                        @click="actionSelected"
                      />
                    </div>
                    <div>
                      <label for="renewRadio">{{ $t("expired-document-renew-action") }}</label>
                      <div
                        v-if="currentAction == 'renew'"
                        class="available_action_additional"
                      >
                        <p>
                          {{ numberOfDocuments.toLocaleString() }} expired
                          documents in this folder (any any subfolders):
                        </p>
                        <p>
                          <strong>{{
                            childFolder
                              ? childFolder.path
                              : rootFolderItem.full_path | formatPath
                          }}</strong>
                        </p>
                        <p>
                          These documents will have their retention period
                          renewed and a new expiry date will be calculated.
                        </p>
                        <!-- <div
                          class="note"
                          v-if="hasMultipleRetentionSchedules()"
                        >
                          <p>
                            NOTE: multiple retention schedules are in use for
                            documents in this folder.
                          </p>
                          <p>
                            After this operation, documents may have different
                            expiry dates.
                          </p>
                        </div> -->
                      </div>
                    </div>
                    <div>
                      <input
                        id="changeRetentionRadio"
                        type="radio"
                        name="action"
                        value="change-retention"
                        @click="actionSelected"
                      />
                    </div>
                    <div>
                      <label for="changeRetentionRadio"
                        >{{ $t("expired-document-change-retention-action") }}</label
                      >
                      <div
                        v-if="currentAction == 'change-retention'"
                        class="available_action_additional"
                      >
                        <p>
                          {{ numberOfDocuments.toLocaleString() }} expired
                          documents in the folder (any any subfolders):
                        </p>
                        <p>
                          <strong>{{
                            childFolder
                              ? childFolder.path
                              : rootFolderItem.full_path | formatPath
                          }}</strong>
                        </p>
                        <p>
                          Expiry will be reset for the period defined on the new
                          retention schedule:
                        </p>
                        <select
                          id="select-retention-schedule"
                          v-model="newRetentionSchedule"
                          class="retention-select"
                          @change="retentionScheduleChanged"
                          required
                        >
                          <option value="" selected>
                            {{
                              $t(
                                "retention-schedule-picker.default-schedule-option"
                              )
                            }}
                          </option>
                          <option
                            v-for="schedule in availableRetentionSchedules"
                            :key="schedule.id"
                            :value="schedule.id"
                          >
                            {{ schedule.id }} -
                            {{ schedule.title | formatScheduleTitle }} ({{
                              schedule.period
                            }})
                          </option>
                        </select>
                        <!-- <div
                          class="note"
                          v-if="hasMultipleRetentionSchedules()"
                        >
                          <p>
                            NOTE: multiple retention schedules are in use for
                            documents in this folder.
                          </p>
                          <p>
                            After this operation, all documents will have the
                            same retention schedule and expiry.
                          </p>
                        </div> -->
                      </div>
                    </div>
                    <div>
                      <input
                        id="saveHistoricRadio"
                        type="radio"
                        name="action"
                        value="save-historic"
                        @click="actionSelected"
                      />
                    </div>
                    <div>
                      <label for="saveHistoricRadio"
                        >{{ $t("expired-document-save-historic-action") }}</label
                      >
                      <div
                        v-if="currentAction == 'save-historic'"
                        class="available_action_additional"
                      >
                        <p>
                          {{ numberOfDocuments.toLocaleString() }} expired
                          documents in the folder (any any subfolders):
                        </p>
                        <p>
                          <strong>{{
                            childFolder
                              ? childFolder.path
                              : rootFolderItem.full_path | formatPath
                          }}</strong>
                        </p>
                        <p>
                          Documents marked in this way will no longer be subject
                          to retention policy and will no longer appear on the
                          Document Retention page.
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </Popup>
              <Popup
                v-if="!showAdditionalActionsDialog && showSummaryDialog"
                :allowCancel="false"
                @confirmed="hideSummary"
              >
                <template v-slot:header>
                  <span v-if="actionResponse && !actionResponse.errorCount">Operation Complete - {{ $t(`expired-document-${currentAction}-action`) }}</span>
                  <span v-if="actionResponse && actionResponse.errorCount">Operation Failed - {{ $t(`expired-document-${currentAction}-action`) }}</span>
                </template>
                <template v-slot:body>
                  <div class="operation_summary" v-if="actionResponse && !actionResponse.errorCount">
                    <div class="label">Total documents:</div><div>{{ ( actionResponse ? actionResponse.totalItemCount : 0) | formatNumber }}</div>
                    <div class="label">Documents processed:</div><div>{{ (actionResponse ? actionResponse.itemSuccessCount : 0) | formatNumber }}</div>
                    <div class="label">Documents failed:</div><div>{{ (actionResponse ? actionResponse.itemFailedCount : 0) | formatNumber }}</div>
                  </div>
                  <div v-else>
                    <div class="operation_summary">
                    <div class="label">Total errors:</div><div>{{ ( actionResponse ? actionResponse.errorCount : 0) | formatNumber }}</div>
                    </div>
                    <div>Ask your administrator to check the logs for the failure reason(s)</div>
                  </div>
                </template>
              </Popup>
            </td>
          </tr>
          <template v-for="item in searchResults.documents">
            <ExpiredItemRoot
              v-if="item.path.endsWith('||.')"
              :key="item.key"
              :ref="item.key"
              :item="item"
              v-on:navigate="navigate"
              v-on:action="itemAction"
            />
            <ExpiredItem
              v-else
              :key="item.key"
              :ref="item.key"
              :item="item"
              v-on:navigate="navigate"
              v-on:action="childActionHandler"
            />
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.summary {
  color: #01212e;
  margin-bottom: 0.4rem;
  margin-top: 0.75rem;
  font-size: 0.8rem;
  //font-weight: bold;

  a:hover {
    text-decoration: underline;
  }
}

div.main {
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0px;
  cursor: pointer;

  .warning {
    display:inline-block;
    background-color: orange;
    padding: 0.5rem;
    border: solid 1px darkorange;
    //width:50%;
    //margin-top: 0.5rem;
    border-radius: 0.5rem;
  }

  .not-a-warning {
    margin-top: 0.25rem;
  }

  .icon {
    color: #4f4f4f;
  }
}

div.available_actions {
  display: grid;
  grid-template-columns: max-content 450px;
  grid-template-rows: max-content max-content max-content max-content;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;

  div.available_action_additional {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #4f4f4f;

    p,
    div {
      margin-top: 0.5rem;
    }

    .note {
      margin-top: 1rem;
    }
  }
}

div.operation_summary {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: max-content max-content max-content;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  font-size: 0.8rem;

  .label {
    font-weight: bold;
  }
}

div.confirm_action > p {
  margin-top: 0.5rem;
}

.retention-select {
  width: 95%;
  margin-top: 1rem;
  option {
    font-size: 0.8rem;
  }
}
.loading-message,
.empty-message {
  font-weight: bold;
  text-align: center;
}

.listing {
  width: 100%;
  font-size: 0.875rem;

  th {
    font-weight: bold;
    color: #5b5b5b;
  }

  td.icon {
    width: 1px;
    color: #4f4f4f;
  }

  th.icon,
  th.actions,
  th.options {
    width: 1px;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #ebebeb;
      //height: 2rem;
    }
  }

  th,
  ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}

button {
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #dcdcdc;
}
</style>
