<script>
import Downloads from "./Downloads.vue";

export default {
  components: {
    Downloads,
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'downloads' }, label: this.$t('downloads') }]);
  },
};
</script>

<template>
  <Downloads />
</template>