<script>
import { fileMixins } from "../../../mixins/file.js";
import {formatISODate} from "../../../util/date";

export default {
  mixins: [fileMixins],
  props: ["item"],
  data() {
    return {
    }
  },
  methods: {
     toFileProperties(){
       if (this.item.toType === "folder") {
          this.$router.push({ path: "/browse/" + this.item.toId,   params: { item: this.item }});
       } else {
          this.$router.push({ path: "/properties/" + this.item.toId,   params: { item: this.item }});
       }
    },
    formatCreatedOnDate(createdOnISODate){
      return formatISODate(createdOnISODate);
    }
  },
};
</script>

<template>
  <tbody>
  <tr class="item" @click.prevent="$emit('selected')" :class="{ opened: false }">
    <td class="icon">
      <m-icon class="material-icons-outlined" icon="shortcut" />
      <span class="secret-dot" v-if="item.isSecret"></span>
    </td>
    <td class="name">
      <a href="#" @click.prevent="toFileProperties">{{ item.name }}</a>
      <div class="tag-list" v-if="item.tags && item.tags.length">
            <span>{{ item.tags.join(", ") }}</span>
          </div>
    </td>
    <td>{{ formatCreatedOnDate(item.created) }}</td>
    <td></td>
    <td></td>
    <td class="actions">
    </td>
    <td class="options">
    </td>
  </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.item {
  .actions {
    text-align: right;
    white-space: nowrap;

    .secret-status, .suspended-status {
      margin: -0.5rem 0 -0.5rem 1rem;
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
      background: #F09F26;
    }
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

    .options {
      color: #4F4F4F;
    }
  }

  .icon {
    color: #4F4F4F;
    display: flex;
    align-items: center;
  }

  .secret-dot {
    display: inline-block;
    background: #F09F26;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  .options {
    color: #CACACA;

    &:hover {
      color: #4F4F4F;
    }
  }

  div.tag-list {
    margin-top: 0.5rem;
  }
  div.tag-list span {
    font-size: 0.7rem;
    font-style: italic;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}

tr.options-bar {
  background: #EBEBEB;
}

</style>
