<script>
import {folderPathCacheMixins} from "@/mixins/folderPathCache";
import Search from "./Search";

export default {
  mixins: [folderPathCacheMixins],
  components: {
    Search,
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'search', query: this.$route.query }, label: this.$t('search-results', { query: this.$route.query.q }) }]);
  },
};
</script>

<template>
  <Search></Search>
</template>
