<script>
import {retentionMixins} from "../../../mixins/retention.js";
import {notificationMixins} from "../../../mixins/notification";
import DeactivateRetentionSchedule from "./DeactivateRetentionSchedule.vue";
import EditRetentionSchedule from "./EditRetentionSchedule.vue";
import {mapState} from "vuex";

export default {
  props: ["retentionSchedule"],
  ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  emits: [ "modified" ],
  mixins: [ retentionMixins, notificationMixins ],
  components: {
    DeactivateRetentionSchedule, EditRetentionSchedule
  },
  data() {
    return {
      isDeactivating: false,
      isActivating: false,
      isEditing: false,
    };
  },
  methods: {
    deactivatingClosed(changed) {
      this.isDeactivating = false;
      if (changed) {
        this.$emit("modified");
      }
    },
    async activate() {
      await this.activateRetentionSchedule(this.retentionSchedule.id);
      this.showSuccess(this.$t("retention-schedule-activated", { item: this.getTitle() }));
      this.$emit("modified");
    },
    editingClosed() {
      this.isEditing = false;
    },
    modified() {
      this.isEditing = false;
      this.$emit("modified");
    },
    getTitle() {
      return `${this.retentionSchedule.id} - ${this.retentionSchedule.title}`;
    },
  },
};
</script>

<template>
  <tbody>
    <tr class="user" @click.prevent="$emit('selected')">
      <td class="active">
        <m-icon v-if="!retentionSchedule.isActive" icon="visibility_off"/>
        <m-icon v-if="retentionSchedule.isActive" icon="visibility_on"/>
      </td>
      <td class="icon">
        <m-icon icon="date_range" />
      </td>
      <td class="name">
        <a href="#" @click.prevent.stop="$emit('selected')">{{ retentionSchedule.id }}</a>
      </td>
      <td>{{ retentionSchedule.category }}</td>
      <td>{{ retentionSchedule.title }}</td>
      <td>{{ retentionSchedule.period }}</td>
      <td>{{ retentionSchedule.region}}</td>
      <td class="actions">
      <button @click.stop="isEditing = true">{{ $t("retention-schedule-edit") }}</button>
      <button v-if="retentionSchedule.isActive" @click.stop="isDeactivating = true">{{ $t("retention-schedule-deactivate") }}</button>
      <button v-if="!retentionSchedule.isActive" @click.stop="activate">{{ $t("retention-schedule-activate") }}</button>

      <DeactivateRetentionSchedule v-if="isDeactivating" :key="retentionSchedule.id" :retentionSchedule="retentionSchedule" @closed="deactivatingClosed"/>
      <EditRetentionSchedule v-if="isEditing" :key="retentionSchedule.id" :retentionSchedule="retentionSchedule" @closed="editingClosed" @modified="modified"/>
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.user {
  cursor: pointer;

  .actions {
    text-align: right;
    white-space: nowrap;
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

  }

  .icon {
    color: #4F4F4F;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}
</style>