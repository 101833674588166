<script>
import { fileMixins } from "../../../mixins/file.js";
import { folderPathCacheMixins } from "../../../mixins/folderPathCache";

export default {
  mixins: [ fileMixins, folderPathCacheMixins ],
  data() {
    return {
      url: "",
    };
  },
  methods: {
    async getFileDownloadUrl(id) {
      const data = await this.callAPI("item/download-token", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ids: [id] }),
      });
      return data.urls[id];
    },
    async updateBreadcrumbs() {
      const path = await this.getFolderPathCached(this.$route.params.id);
      const breadcrumbs = this.$store.state.crumbs;
      const initialCrumb =
        breadcrumbs && breadcrumbs.length > 0
          ? breadcrumbs[0]
          : { to: { name: "all" }, label: this.$t("nav.all") };
      await this.$store.dispatch("setBreadcrumbs", [
        initialCrumb,
        ...path.map((item) => ({
          to: { name: "browse", params: { id: item.id } },
          label: item.name,
        })),
      ]);
    },
  },
  mounted() {
    this.updateBreadcrumbs();
    const id = this.$route.params.id;
    this.getFileDownloadUrl(id)
      .then((url) => {
        this.url = url;
        window.location.href = url;
      })
      .catch((err) => console.log(err));
  },
  watch: {
    "$route.params.id"() {
      this.updateBreadcrumbs();
    },
  },
};
</script>

<template>
  <div v-if="this.url">
    <p>Please wait for your download to start. If it does not start automatically, <a target="_blank" :href="url" title="Manual download link">CLICK HERE</a></p>
  </div>
</template>