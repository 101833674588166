<script>
import {retentionMixins} from "../../../mixins/retention.js";
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [retentionMixins, notificationMixins],
  props: {
    retentionSchedule: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
    };
  },
  methods: {
    getTitle() {
      return `${this.retentionSchedule.id} - ${this.retentionSchedule.title}`;
    },
    async deactivateConfirmed() {
      await this.deactivateRetentionSchedule(this.retentionSchedule.id);
      this.showSuccess(this.$t("retention-schedule-deactivated", { item: this.getTitle() }));
      this.$emit("closed", true);
    },
  },
};
</script>

<template>
  <Popup @cancelled="$emit('closed', false)" @confirmed="deactivateConfirmed">
    <template v-slot:header>{{ $t("retention-schedule-deactivate") }}</template>
    <template v-slot:body>
      <div class="message">{{ $t("retention-schedule-deactivate-message", { item: getTitle() }) }}</div>
      <div class="message">{{ $t("retention-schedule-deactivate-message-confirm") }}</div>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
  .message {
    margin-bottom: 1.5em;
  }
</style>