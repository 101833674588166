<script>
import { folderPathCacheMixins } from "../../../mixins/folderPathCache.js";
import { notificationMixins } from "../../../mixins/notification.js";
import {folderMixins} from "../../../mixins/folder";
import FileItem from "../browser/FileItem.vue";
import Pagination from "../../common/Pagination";
import ResultsFilter from "../../common/ResultsFilter.vue";

export default {
  mixins: [folderPathCacheMixins, notificationMixins, folderMixins],
  data() {
    return {
      page: 0,
      pageSize: 25,
      filter: "",
      currentSort: 'label',
      currentSortDir: 'asc',
    };
  },
  components: {
    FileItem,
    Pagination,
    ResultsFilter,
  },
  asyncComputed: {
    downloadItems() {
      return this.getDownloadItems({
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + ',' + this.currentSortDir,
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
        filter: this.filter,
      });
      
    }
  },
  computed: {
    pages() {
      if (!this.downloadItems) {
        return 0;
      }
      return Math.ceil(this.downloadItems.totalItemCount / this.pageSize);
    },
    sortIcon(){
      if (this.currentSortDir === 'asc') { return "arrow_downward";}
      else return "arrow_upward";
    }
  },
  methods: {
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    folderSelected(item) {
      this.$router.push({ name: "browse", params: { id: item.id } });
    },
    hasRecords() {
      return this.downloadItems && 
        this.downloadItems.allItems && 
        this.downloadItems.allItems.length;
    },
    sort(s) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      this.currentSort=s;
    },
  },
};
</script>

<template>
  <div class="shared">
    <div v-if="$asyncComputed.downloadItems.updating" class="loading"></div>
    <div v-else-if="$asyncComputed.downloadItems.error" class="load-error">Failed to load my downloads content</div>
    <div v-else-if="!hasRecords()">
      You do not have any download items
    </div>
    <portal to="actions">
      <ResultsFilter @filterChange="setFilter"/>
    </portal>
    <table class="listing" v-if="hasRecords()">
      <thead>
        <tr>
          <th class="icon"></th>
          <th class="name" @click="sort('label')">Name
            <span v-if="currentSort == 'label'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="created-on" @click="sort('createdOn')">Created on
             <span v-if="currentSort == 'createdOn'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="expires-on" @click="sort('expirationDate')">Expires on
             <span v-if="currentSort == 'expirationDate'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="size" @click="sort('actualSize')">Size
             <span v-if="currentSort == 'actualSize'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="actions">Actions</th>
          <th class="options">Options</th>
        </tr>
      </thead>
      <template v-for="item in downloadItems.allItems">
        <FileItem v-if="item.type === 'file'" :key="item.id" :item="item" @modified="refresh" />
      </template>
    </table>    
    <Pagination v-if="hasRecords()" 
      :page="page" 
      :pages="pages" 
      :pageSize="pageSize" 
      :totalRecords="downloadItems.totalItemCount" 
      @pageChange="setPage" 
      @pageSizeChange="setPageSize" />
  </div>
</template>

<style lang="scss" scoped>
.loading, .load-error {
  text-align: center;
  margin-top: 4rem;
}
input.filter {
    flex: 1 1 auto;
    background: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    color: black;
    padding: 0.5rem 1rem;
}
.sort-icon {
  font-size: 14px;
}

.listing {
  width: 100%;
  font-size: 0.875rem;

  th {
    font-weight: bold;
    color: #5B5B5B;
  }

  th.icon, th.actions, th.options {
    width: 1px;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #EBEBEB;
      height: 2rem;
    }
  }

  th, ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}
</style>
