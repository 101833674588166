<script>
import { notificationMixins } from "../../../mixins/notification";
import { expiryActions } from "../../../mixins/expiry";
import moment from "moment";

export default {
  mixins: [notificationMixins, expiryActions],
  props: ["item" ],
  emits: ["navigate", "action"],
  components: {},
  data() {
    return {
      subFolders: [],
      lastFolder: null,
      id: null
    };
  },
  asyncComputed: {
    async summary() {
      return this.getExpiredItemSummary(this.item.path)
        .then((response) => {
          return response.documents[0];
        });
    },
  },
  mounted() {
    this.parseSubFolders();
  },
  methods: {
    actionHandler(action) {
      this.$emit("action", action, this.item);
    },
    filterByFolder(subFolder) {
      this.$emit("navigate", {
        folderPath: subFolder,
      });
    },
    parseSubFolders() {
      const separator = "||";
      const pathElements = this.item.path.split(separator);
      const lastIndex = pathElements.length - 1;

      let currentPath = "";

      pathElements.forEach((element, index) => {
        currentPath = index === 0 ? element : currentPath + "||" + element;
        const subFolder = {
          name: element,
          path: currentPath,
          isLastIndex: index === lastIndex,
        };
        this.subFolders.push(subFolder);
        if (subFolder.isLastIndex) {
          this.lastFolder = subFolder;
        }
      });
    }
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return "-";
      }
      return moment(String(value)).format("DD/MM/YYYY");
    },
    formatNumber(value) {
      return Number(value);
    },
    formatKeywords(keywords) {
      return String(keywords);
    },
    formatTimeTaken(milliseconds) {
      return `${(milliseconds * 1.0) / 1000}s`;
    }
  },
};
</script>

<template>
  <tr v-if="summary" class="item-result" :key="item.parentId">
    <td>
      <div class="main">
        <div class="icon" title="Open Folder">
          <span v-if="lastFolder && lastFolder.name !== '.'">
            <m-icon
              icon="folder_copy"
              v-on:click="filterByFolder(lastFolder.path)"
            />
          </span>
          <span v-else> &#8226; </span>
        </div>
        <div v-if="lastFolder && lastFolder.name !== '.'" class="path">
          <span v-for="subFolder in subFolders" :key="subFolder.path">
            <span v-if="subFolder.isLastIndex && subFolder.name !== '.'"
              ><a
                href="javascript:void(0)"
                v-on:click="filterByFolder(subFolder.path)"
                >{{ subFolder.name }}</a
              ></span
            >
            <span v-else-if="subFolder.isLastIndex && subFolder.name === '.'">{{
              subFolder.name
            }}</span>
            <span v-else
              ><a
                href="javascript:void(0)"
                v-on:click="filterByFolder(subFolder.path)"
                >{{ subFolder.name }}</a
              >&nbsp;&gt;&nbsp;</span
            >
          </span>
        </div>
        <div v-else></div>
        <div></div>
        <div></div>
        <div class="summary">
          <div class="label" :title="$t('expired-document-count-description')">
            Total Expired Files:
          </div>
          <div v-if="!summary.numberOfDocuments">-</div>
          <div v-else>{{ summary.numberOfDocuments.toLocaleString() }}</div>
          <div class="label" :title="$t('expired-document-age-description')">
            Age (days):
          </div>
          <div v-if="!summary.maxExpiredAge">-</div>
          <div v-else>{{ summary.maxExpiredAge.toLocaleString() }}</div>
          <div
            class="label"
            :title="$t('expired-document-schedule-description')"
          >
            Schedules:
          </div>
          <div>
            <div v-if="!summary.documentsBySchedule || summary.documentsBySchedule.length === 0">-</div>
            <div
              v-else
              v-for="schedule in summary.documentsBySchedule"
              :key="schedule.schedule"
            >
              {{
                `${
                  schedule.schedule
                } (${schedule.numberOfDocuments.toLocaleString()})`
              }}
            </div>
          </div>
          <div
            class="label"
            :title="$t('expired-document-steward-description')"
          >
            Stewards:
          </div>
          <div>
            <div v-if="!summary.documentsBySteward || summary.documentsBySteward.length === 0">-</div>
            <div
              v-else
              v-for="steward in summary.documentsBySteward"
              :key="steward.steward"
            >
              {{
                `${
                  steward.steward
                } (${steward.numberOfDocuments.toLocaleString()})`
              }}
            </div>
          </div>
        </div>
        <div class="actions">
          <button
            @click.stop="actionHandler('open')"
            class="primary"
            title="Open Folder for Review"
          >
            <m-icon class="material-icons-outlined" icon="folder_open"></m-icon>
          </button>
          <button
            @click.stop="actionHandler('download')"
            title="Download Folder for Offline Review"
          >
            <m-icon
              class="material-icons-outlined"
              icon="file_download"
            ></m-icon>
          </button>
          <button
            @click.stop="actionHandler('additional-actions')"
            title="View Available Actions"
          >
            <m-icon class="material-icons-outlined" icon="menu"></m-icon>
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.item-result {
  &:hover {
    background: #eeeeee;
  }
}

.icon {
  color: #4f4f4f;
  width: 30px;
}

div.path {
  color: #01212e;
  margin-bottom: 0.4rem;
  margin-top: 0.25rem;
  font-size: 0.8rem;
  //font-weight: bold;

  a:hover {
    text-decoration: underline;
  }
}

div.actions {
  float: right;
  text-align: right;
  display:flex;
  flex-wrap:wrap;

  button {
    margin-left: 0.5rem;
    cursor: pointer;
    font-size: 0.3rem;
    padding: 0.25rem 0.25rem;
    background: #dcdcdc;
    height: 35px;
    border-radius: 0.25rem;
    color: #4f4f4f;
    border: solid 1px #4f4f4f;
  }
}

div.main {
  display: grid;
  grid-template-columns: 30px auto 200px;
  grid-template-rows: max-content auto;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0px;
}

div.summary {
  color: #4f4f4f;
  font-size: 0.7rem;
  float: left;
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-template-rows: max-content max-content;
  grid-column-gap: 0.5rem;
  grid-row-gap: 5px;

  div.label {
    font-weight: bold;
    text-align: right;
  }
}

div.available_actions {
  display: grid;
  grid-template-columns: max-content 450px;
  grid-template-rows: max-content max-content max-content max-content;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;

  div.available_action_additional {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #4f4f4f;

    p,
    div {
      margin-top: 0.5rem;
    }

    .note {
      margin-top: 1rem;
    }
  }
}

div.confirm_action > p {
  margin-top: 0.5rem;
}

.retention-select {
  width: 95%;
  margin-top: 1rem;
  option {
    font-size: 0.8rem;
  }
}
</style>
