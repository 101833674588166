<script>
import {keywordMixins} from "../../../mixins/keyword.js";
import {notificationMixins} from "../../../mixins/notification";
import Pagination from "../../common/Pagination";
import ResultsFilter from "../../common/ResultsFilter.vue";
import KeywordItem from "./KeywordItem.vue";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [notificationMixins, keywordMixins],
  data() {
    return {
      page: 0,
      pageSize: 25,
      filter: "",
      currentSort: "id",
      currentSortDir: "asc",
      isAddNew: false,
      newId: "",
      newLabel: "",
      newComments: "",
      newKeyword: null,
    };
  },
  components: {
    Pagination,
    Popup,
    ResultsFilter,
    KeywordItem
  },
  asyncComputed: {
    keywords() {
      return this.getKeywords({
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + ',' + this.currentSortDir,
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
        filter: this.filter
      });
    }
  },
  computed: {
    pages() {
      if (!this.keywords) {
        return 0;
      }
      return Math.ceil(this.keywords.totalItemCount / this.pageSize);
    },
    sortIcon(){
      if (this.currentSortDir === 'asc') { return "arrow_downward";}
      else return "arrow_upward";
    }
  },
  methods: {
    async addKeywordConfirmed() {
      this.newKeyword = await this.addKeyword({
        id: this.newId,
        label: this.newLabel,
        comments: this.newComments
      });
      this.showSuccess(this.$t("keyword-added", { item: this.getNewTitle() }));
      this.newId = "";
      this.newLabel = "";
      this.newComments = "";
      this.isAddNew = false;
      this.refresh();
    },
    getNewTitle() {
      return `${this.newId} - ${this.newLabel}`;
    },
    refresh() {
      this.$asyncComputed.keywords.update();
    },
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    hasRecords() {
      return this.keywords && this.keywords.keywords && this.keywords.keywords.length;
    },
    sort(s) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      this.currentSort=s;
    },
  },
};
</script>

<template>
  <div class="shared">
    <div v-if="$asyncComputed.keywords.updating" class="loading"></div>
    <div v-else-if="$asyncComputed.keywords.error" class="load-error">Failed to load content</div>
    <div v-else-if="!hasRecords() && !this.filter">
      There are no keywords to show
    </div>
    <div v-else-if="!hasRecords() && this.filter">
      There are no keywords matching the filter criteria to show
    </div>
    <portal to="actions">
      <ResultsFilter v-if="hasRecords()" @filterChange="setFilter"/>
      <button class="primary" @click="isAddNew = true">
        <m-icon icon="add" />
        {{ $t("keyword-add") }}
      </button>
    </portal>

    <Popup v-if="isAddNew" :valid="newId.length === 2 && newLabel.length >= 2" @cancelled="isAddNew = false" @confirmed="addKeywordConfirmed">
      <template v-slot:header>{{ $t("keyword-add-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="newId" outlined required maxlength="2">
          <m-floating-label>{{ $t("keyword-add-id-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-text-field v-model="newLabel" outlined required maxlength="80">
          <m-floating-label>{{ $t("keyword-add-label-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-text-field v-model="newComments" outlined maxlength="2000">
          <m-floating-label>{{ $t("keyword-add-comments-placeholder") }}</m-floating-label>
        </m-text-field>
      </template>
    </Popup>

    <table class="listing" v-if="hasRecords()">
      <thead>
        <tr>
          <th class="icon"></th>
          <th class="icon"></th>
          <th class="keyword-id" @click="sort('id')">ID
            <span v-if="currentSort == 'id'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="keyword-label" @click="sort('label')">Label
             <span v-if="currentSort == 'label'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="keyword-comments" @click="sort('comments')">Comments
            <span v-if="currentSort == 'comments'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
        </tr>
      </thead>
      <template v-for="keyword in keywords.keywords">
        <KeywordItem :key="keyword.id" :keyword="keyword" v-on:modified="refresh"/>
      </template>
    </table>
    <Pagination v-if="hasRecords()"
      :page="page" 
      :pages="pages" 
      :pageSize="pageSize" 
      :totalRecords="keywords.totalItemCount" 
      @pageChange="setPage" 
      @pageSizeChange="setPageSize"/>
  </div>
</template>

<style lang="scss" scoped>
.loading, .load-error {
  text-align: center;
  margin-top: 4rem;
}
input.filter {
    flex: 1 1 auto;
    background: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    color: black;
    padding: 0.5rem 1rem;
}
button.add-keyword {
  margin-right: 0.5em;
}
.sort-icon {
  font-size: 14px;
}
.listing {
  width: 100%;
  font-size: 0.875rem;

  th {
    font-weight: bold;
    color: #5B5B5B;
  }

  th.icon, th.actions, th.options {
    width: 1px;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #EBEBEB;
    }
  }

  th, ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}
</style>
