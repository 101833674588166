<script>
import ExpiredItems from "./ExpiredItems.vue";

export default {
  components: {
    ExpiredItems,
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'expired-items' }, label: this.$t("expired-items") }]);
  },
};
</script>

<template>
  <ExpiredItems :folderPath="$route.params.folderPath ? $route.params.folderPath : ''"/>
</template>
