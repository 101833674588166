<script>
import {keywordMixins} from "../../../mixins/keyword.js";
import {notificationMixins} from "../../../mixins/notification";
import DeactivateKeyword from "./DeactivateKeyword.vue";
import EditKeyword from "./EditKeyword.vue";
import {mapState} from "vuex";

export default {
  props: ["keyword"],
  ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  emits: [ "modified" ],
  mixins: [ keywordMixins, notificationMixins ],
  components: {
    DeactivateKeyword, EditKeyword
  },
  data() {
    return {
      isDeactivating: false,
      isActivating: false,
      isEditing: false,
    };
  },
  methods: {
    deactivatingClosed(changed) {
      this.isDeactivating = false;
      if (changed) {
        this.$emit("modified");
      }
    },
    async activate() {
      await this.activateKeyword(this.keyword.id);
      this.showSuccess(this.$t("keyword-activated", { item: this.getTitle() }));
      this.$emit("modified");
    },
    editingClosed() {
      this.isEditing = false;
    },
    modified() {
      this.isEditing = false;
      this.$emit("modified");
    },
    getTitle() {
      return `${this.keyword.id} - ${this.keyword.label}`;
    },
  },
};
</script>

<template>
  <tbody>
    <tr class="user" @click.prevent="$emit('selected')">
      <td class="active">
        <m-icon v-if="!keyword.isActive" icon="visibility_off"/>
        <m-icon v-if="keyword.isActive" icon="visibility_on"/>
      </td>
      <td class="icon">
        <m-icon icon="tag" />
      </td>
      <td class="name">
        <a href="#" @click.prevent.stop="$emit('selected')">{{ keyword.id }}</a>
      </td>
      <td>{{ keyword.label }}</td>
      <td>{{ keyword.comments }}</td>
      <td class="actions">
      <button @click.stop="isEditing = true">{{ $t("keyword-edit") }}</button>
      <button v-if="keyword.isActive" @click.stop="isDeactivating = true">{{ $t("keyword-deactivate") }}</button>
      <button v-if="!keyword.isActive" @click.stop="activate">{{ $t("keyword-activate") }}</button>

      <DeactivateKeyword v-if="isDeactivating" :key="keyword.id" :keyword="keyword" @closed="deactivatingClosed"/>
      <EditKeyword v-if="isEditing" :key="keyword.id" :keyword="keyword" @closed="editingClosed" @modified="modified"/>
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.user {
  cursor: pointer;

  .actions {
    text-align: right;
    white-space: nowrap;
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

  }

  .icon {
    color: #4F4F4F;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}
</style>