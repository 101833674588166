<script>
import { notificationMixins } from "../../../mixins/notification";
import { searchMixins } from "../../../mixins/search";
import Pagination from "../../common/Pagination";
import SearchResultsFolderItem from "./SearchResultsFolderItem.vue"
import SearchResultsFileItem from "./SearchResultsFileItem.vue"
import {folderPathCacheMixins} from "@/mixins/folderPathCache";
import { folderDownloadMixins } from "../../../mixins/folderdownload.js";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [searchMixins, notificationMixins, folderPathCacheMixins, folderDownloadMixins],
  components: {
    Pagination, SearchResultsFolderItem, SearchResultsFileItem, Popup
  },
  data() {
    return {
      q: this.$route.query.q,
      parentId: this.$route.query.parentId ?? 0,
      page: this.$route.query.page ?? 0,
      pageSize: this.$route.query.pageSize ?? 25,
      currentSort: this.$route.query.sort ?? "label",
      currentSortDir: this.$route.query.sortDir ?? "asc",
      showLoader: false,
      loading: true,
      isDownloading: false,
      isDownloaded: false,
      downloadSelectedResults: null,
      selectedItems: []
    };
  },
  asyncComputed: {
    async searchResults() {
      return await this.search({
        query: {
          q: this.q,
          parentId: this.parentId,
          page: this.page,
          pageSize: this.pageSize,
          sort: this.currentSort,
          sortDirection: this.currentSortDir,
        },
        showLoader: false,
        loading: true
      }).then(response => {
        this.loading = false;
        this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'search', query: { q: this.q, page: this.page, pageSize: this.pageSize, sort: this.sort, sortDirection: this.sortDirection} }, 
          label: this.$t('search-results', { query: this.q }) }]);
        return response;
      })
    },
  },
  computed: {
    pages() {
      if (!this.searchResults.totalItemCount) {
        return 0;
      }
      return Math.ceil(this.searchResults.totalItemCount / this.pageSize);
    },
    sortIcon() {
      if (this.currentSortDir === "asc") {
        return "arrow_upward";
      } else return "arrow_downward";
    },
  },
  methods: {
    refresh() {
      this.$asyncComputed.searchResults.update();
    },
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    sort(s) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      this.currentSort = s;
    },
    updateSelectedItems(itemId) {
      const index = this.selectedItems.indexOf(itemId);

      if (index === -1) {
        this.selectedItems.push(itemId);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    downloadHandler() {
      this.isDownloading = true;
    },
    async downloadConfirmed() {
      this.isDownloading = false;
      let results = await this.downloadSelected(this.selectedItems, this.q);
      console.log(results);
      this.downloadSelectedResults = results.downloadItems;
    },
    downloadQueueClosed() {
      this.isDownloaded = false;
      this.isDownloading = false;
      this.downloadSelectedResults = null;
    },
    formatFileSizeInMegabytes(fileSizeInBytes) {
      let fileSizeInMegabytes = fileSizeInBytes / 1024 / 1024;
      return fileSizeInMegabytes.toLocaleString();
    },
  },
  watch: {
    // VueJS routing is ridiculous
    // https://github.com/vuejs/vue-router/issues/2430
    $route(to) {
      this.q = to.query.q;
      this.page = to.query.page ?? 0;
      this.pageSize = to.query.pageSize ?? 25;
      this.currentSort = to.query.sort ?? "label";
      this.currentSortDir = to.query.sortDir ?? "asc";
      this.searchResults = [];
      this.totalItems = 1;
      this.loading = true;
    },
  },
  filters: {
    formatTimeTaken(milliseconds) {
      return `${(milliseconds * 1.0) / 1000}s`;
    },
  },
};
</script>

<template>
  <div class="search-results">
    <div v-if="this.selectedItems.length" style="float:right;width:25%;">
      <button @click.stop="downloadHandler" style="float:right">
        <m-icon
            class="material-icons-outlined"
            icon="file_download"
        ></m-icon>
      </button>
    </div>
    <div v-if="this.loading" class="loading-message">
      {{ $t("search-results-loading") }}
    </div>
    <div
      v-if="!this.loading && this.searchResults && this.searchResults.documents && this.searchResults.documents.length === 0"
      class="empty-message"
    >
      {{ $t("search-results-empty", { query: this.q }) }}
    </div>
    <div
      v-if="!this.loading && this.searchResults && this.searchResults.documents && this.searchResults.documents.length > 0"
      class="search-results"
    >
      <table class="listing">
        <thead>
          <tr>
            <th v-if="searchResults.exactTotalItemCount" colspan="3">
              {{ searchResults.totalItemCount }} results ({{
                searchResults.queryTime | formatTimeTaken
              }})
            </th>
            <th v-if="!searchResults.exactTotalItemCount" colspan="3">
              &gt; {{ searchResults.totalItemCount }} results ({{
                searchResults.queryTime | formatTimeTaken
              }})
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in searchResults.documents">
            <SearchResultsFolderItem v-if="item.type === 'folder'" :item="item" :key="(item.id) + '_folder'" v-on:itemSelected="updateSelectedItems"/>
            <SearchResultsFileItem v-else :item="item" :key="(item.id) + '_file'" v-on:itemSelected="updateSelectedItems"/>
          </template>
        </tbody>
      </table>

      <Pagination
        v-if="searchResults.documents.length"
        :page="page"
        :pages="pages"
        :pageSize="pageSize"
        :totalRecords="searchResults.totalItemCount"
        @pageChange="setPage"
        @pageSizeChange="setPageSize"
        :condensed="true"
      />

      <Popup
          v-if="isDownloading"
          @cancelled="isDownloading = false"
          @confirmed="downloadConfirmed"
      >
        <template v-slot:header>{{ $t("download-selected-title") }}</template>
        <template v-slot:body>
          <div
              class="download-message"
              v-html="$t('download-selected-message', { count: selectedItems.length })"
          ></div>
        </template>
      </Popup>
      <Popup
          v-if="downloadSelectedResults"
          @confirmed="downloadQueueClosed"
          :allowCancel="false"
      >
        <template v-slot:header>{{
            $t("download-queued-selected-title")
          }}</template>
        <template v-slot:body>
          <p>{{ $t("download-queued-selected-message") }}</p>
          <br />
          <ul>
            <li>
              {{
                $t("download-queued-selected-number-of-files", {
                  numberOfFiles: downloadSelectedResults.numberOfFiles.toLocaleString(),
                })
              }}
            </li>
            <li>
              {{
                $t("download-queued-selected-total-size", {
                  totalSize: formatFileSizeInMegabytes(
                      downloadSelectedResults.downloadSize
                  ),
                })
              }}
            </li>
            <li>
              {{
                $t("download-queued-selected-number-of-files-and-folders-excluded", {
                  numberOfFilesExcluded:
                  downloadSelectedResults.numberOfFilesNotAdded,
                })
              }}
            </li>
          </ul>
        </template>
      </Popup>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.search-results > h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
}
.loading-message,
.empty-message {
  font-weight: bold;
  text-align: center;
}

.sort-icon {
  font-size: 14px;
}
.listing {
  width: 100%;
  font-size: 0.875rem;

  th {
    font-weight: bold;
    color: #5b5b5b;
  }

  td.select-for-download {
    width: 1px;
  }

  td.icon {
    width: 1px;
  }

  th.icon,
  th.actions,
  th.options {
    width: 1px;
  }

  .expires-on {
    width: 6rem;
  }
  .created-on {
    //padding-left: 2rem;
    width: 6rem;
  }
  .size {
    width: 6rem;
  }
  .project-id {
    width: 8rem;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #ebebeb;
      //height: 2rem;
    }
  }

  th,
  ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}

.item-result {
  &:hover {
    cursor: pointer;
    background: #eeeeee;
  }
}

.icon {
  color: #4f4f4f;
}

.pill {
  border: none;
  color: black;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 8px 0px 0px 0px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 0.7rem;
}
div.suspension-order {
  background-color: #f09f26;

  ::v-deep em {
    font-weight: bold;
    font-style: normal;
  }
}
.project-id {
  background-color: #a0a0a0;
}
div.tag-list {
  float: left;
  margin-top: 0.5rem;

  span {
    font-size: 0.7rem;
  }
}
div.path {
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
}
div.name {
  font-size: 1.1rem;
  color: #01212e;

  ::v-deep em {
    font-weight: bold;
    font-style: normal;
  }
}
div.score {
  float: right;
  margin-top: 0.5rem;

  span {
    font-size: 0.7rem;
  }
}

button {
  //margin: -0.5rem 0 -0.5rem 1rem;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}
</style>
