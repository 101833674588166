import {apiMixins} from "./api";

const KEYWORD_ENDPOINT = "keyword";

export const keywordMixins = {
  mixins: [apiMixins],
  methods: {
    async getKeywords({ page = 0, pageSize = 0, sort, showLoader = true, filter="" } = {}) {
      return await this.callAPI(KEYWORD_ENDPOINT, {
        method: "GET",
        query: {page, pageSize, sort, filter},
        showLoader,
      });
    },
    async activateKeyword(id) {
        return await this.callAPI(`${KEYWORD_ENDPOINT}/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "isActive": true }),
        });
    },
    async deactivateKeyword(id) {
        return await this.callAPI(`${KEYWORD_ENDPOINT}/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "isActive": false }),
        });
    },
    async updateKeyword({ id = "", label = "", comments = ""} = {}) {
        return await this.callAPI(`${KEYWORD_ENDPOINT}/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "label": label, "comments": comments }),
        });
    },
    async addKeyword({ id = "", label = "", comments = ""} = {}) {
        return await this.callAPI(KEYWORD_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "id": id, "label": label, "comments": comments }),
        });
    }
  },
};
