<script>
import FilePicker from "./FilePicker.vue";
import FolderPicker from "./FolderPicker.vue";
import {uploadFileMixins} from "../../../mixins/fileUpload.js";
import {notificationMixins} from "../../../mixins/notification.js";
import {subjectMixins} from "../../../mixins/subject.js";
import {apiMixins} from "../../../mixins/api.js";
import {prettyPrintFileSize} from "@/util/file";
import Metadata from "../../common/Metadata.vue";
import RetentionSchedule from "./UploadRetentionSchedule.vue";
import { folderMixins } from "../../../mixins/folder.js";
import Popup from "../../common/Popup.vue";

const STATE = {
  FILE_PICK: 0,
  FILE_OPTIONS: 1,
  UPLOADING: 2,
  DONE: 3,
  CANCELLED: 4,
};

export default {
  mixins: [uploadFileMixins, notificationMixins, apiMixins, subjectMixins, folderMixins],
  components: {
    FilePicker,
    FolderPicker,
    Metadata,
    RetentionSchedule,
    Popup,
  },
  props: ["folderId"],
  emits: ["stateChanged"],
  data() {
    return {
      STATE,
      state: STATE.FILE_PICK,
      uploader: null,
      currentItem: 0,
      currentFilename: "",
      collectionFileSize: 0,
      chosenFiles: [],
      progress: 0,
      isSecret: false,
      selectedRetentionSchedule: null,
      currentSubjects: [],
      allSubjects: [],
      currentMetadata: [],
      metadataItem: { metadata: [] },
      folder: null,
      failedFiles: [],
    };
  },
  computed: {
    availableSubjects() {
      return this.allSubjects.filter(s => !this.currentSubjects.includes(s.label));
    }
  },
  asyncComputed: {
    retentionSchedules() {
      return this.callAPI("item/retention-schedules", {
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
      });
    },
  },
  methods: {
    filesReadyHandler(files) {
      if (!files.length) {
        return;
      }
      this.chosenFiles = files;
      this.setState(STATE.FILE_OPTIONS);
      let fileSizeSum = 0;
      this.chosenFiles.forEach(f => {
        // if(f.file.size === 0){
        //   this.showError(f.file.name + " - " + this.$t("zero-byte-file-size"));
        //   this.returnToBrowse();
        // }
        fileSizeSum += parseInt(f.file.size);
      });
      this.collectionFileSize = fileSizeSum;
    },
    returnToBrowse() {
      this.$router.push({name: "browse", params: {id: this.folderId}});
    },
    async startUpload() {
      this.setState(STATE.UPLOADING);
      this.uploader = this.uploadFiles(
          this.chosenFiles.map((item) => ({
            parentItem: {id: this.folderId},
            name: item.file.name,
            subPath: item.path,
            retentionSchedule: this.selectedRetentionSchedule.id,
            file: item.file,
            isSecret: this.isSecret,
            tags: this.currentSubjects,
            metadata: this.currentMetadata,
          })),
          (item, fileName, numberOfItems) => {
            this.currentItem = Math.floor(item);
            this.currentFilename = fileName;
            this.progress = item / numberOfItems;
          },
          (item) => {
            this.showError(this.$t("upload-error", {filename: item.file.name}), {expiryTime: 30000});
            this.failedFiles.push(item);
          },
      );
      let success = false;
      try {
        success = await this.uploader.run();
      } catch (ex) {
        console.log(ex);
      }
      this.uploader = null;
      if (success) {
        this.setState(STATE.DONE);
        this.showSuccess(this.$t("upload-success"));
        setTimeout(() => this.returnToBrowse(), 1000);
      } else {
        this.setState(STATE.FILE_OPTIONS);
      }
    },
    async cancelUpload() {
      if (this.uploader) {
        this.setState(STATE.CANCELLED);
        this.uploader.cancel();
        this.showError(this.$t("upload-cancel"));
      }
      this.returnToBrowse();
    },
    setState(state) {
      this.state = state;
      this.$emit("stateChanged", state); 
    },
    tagAdded(tag) {
      this.currentSubjects.push(tag.value);
    },
    tagRemoved(tag) {
      this.currentSubjects = this.currentSubjects.filter(s => s !== tag);
    },
    metadataChanged(metadata) {
      this.currentMetadata = metadata;
    },
    onRetentionScheduleChangedHandler(selectedRetentionSchedule) {
      this.selectedRetentionSchedule = selectedRetentionSchedule;
    },
    async loadSubjects() {
      var response = await this.getAvailableSubjects(this.folderId, { showLoader: true });
      this.allSubjects = response.subjects;
    },
    async loadFolder() {
      this.getFolder(this.folderId, {
        page: 0,
        pageSize: 1,
        sort: "label,asc"
      })
      .then(response => {
        this.folder = response.self;
      });
    },
    clearFailedFiles() {
      this.failedFiles = [];
      this.setState(STATE.FILE_PICK);
    },
  },
  filters: {
    prettyBytes(value) {
      return prettyPrintFileSize(value);
    }
  },
  created() {
    this.loadSubjects();
    this.loadFolder();
  }
};
</script>

<template>
  <div class="upload">
    <FilePicker v-if="state === STATE.FILE_PICK" @filesReady="filesReadyHandler"/>    
    <FolderPicker v-if="state === STATE.FILE_PICK" @filesReady="filesReadyHandler"/>

    <section class="options" v-if="state >= STATE.FILE_OPTIONS">
      <div>
        <h5 class="label">{{ $t("files-selected") }}</h5>
        <p class="value">{{ this.chosenFiles.length }}</p>
      </div>
      <div>
        <h5 class="label">{{ $t("total-size") }}</h5>
        <p class="value">{{ collectionFileSize | prettyBytes }}</p>
      </div>
    </section>

    <RetentionSchedule
      v-if="state >= STATE.FILE_OPTIONS"
      id="retention-schedule"
      :item="folder"
      @retention-schedule-changed="onRetentionScheduleChangedHandler"
    />
    <section class="confidentiality" v-if="state >= STATE.FILE_OPTIONS">
      <h5>{{ $t("confidentiality-heading") }}</h5>
      <section>
        <label>
          <input type="checkbox" v-model="isSecret">
          {{ $t("confidentiality-label") }}
        </label>
      </section>
    </section>
    <section class="tags" v-if="state >= STATE.FILE_OPTIONS">
        <h5>{{ $t("keywords-heading") }}</h5>
        <section>
          <p>{{ $t("keywords-description") }}</p>
          <m-select
          id="add-tag"
          outlined
          @change="tagAdded"
          style="margin-top: 1rem"
        >
          <option selected value="">{{ $t("keywords-add") }}</option>
          <option
            v-for="subject in availableSubjects"
            :key="subject.id"
            :value="subject.label"
            >{{ subject.label }}</option
          >
        </m-select>
        <m-chip-set v-if="currentSubjects && currentSubjects.length" style="margin-top: 1rem">
          <m-chip v-for="subject in currentSubjects" :key="subject" shouldRemoveOnTrailingIconClick="false">{{ subject }}
            <m-icon icon="cancel" slot="trailingIcon" @click="tagRemoved(subject)"/>
          </m-chip>
        </m-chip-set>
        </section>
     
      </section>

      <Metadata :item="metadataItem" @metadata-changed="metadataChanged" v-if="state >= STATE.FILE_OPTIONS"/>

    <section class="progress" v-if="state >= STATE.UPLOADING">
      <h5 class="label">{{ $t("uploading", { item: this.currentFilename }) }}</h5>
      <p v-if="state === STATE.DONE">{{ $t("upload-complete") }}</p>
      <p v-else>{{ $t("upload-progress", {current: currentItem + 1, total: this.chosenFiles.length}) }}</p>
      <m-linear-progress :buffer="progress > 0 ? 1 : 0" :open="true" :progress="progress"/>
    </section>

    <section class="buttons" v-if="state <= STATE.UPLOADING">
      <button class="primary" :disabled="!selectedRetentionSchedule" @click="startUpload">
        {{ $t("begin-upload") }}
      </button>
      <button @click="cancelUpload">
        {{ $t("cancel") }}
      </button>
    </section>

    <Popup 
      v-if="state === STATE.FILE_OPTIONS && failedFiles.length > 0" :defaultButtons="false"
      @cancelled="clearFailedFiles">
      <template v-slot:header>Failed Files</template>
      <template v-slot:body>
        <div class="failed-files-label">The following files failed to upload:</div>
        <div class="failed-files-block">
          <p v-for="failedFile in failedFiles" :key="failedFile.name">{{ failedFile.subPath }}{{failedFile.name}}</p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="buttons">
        <button type="button" class="primary" @click.stop="clearFailedFiles">{{ $t("cancel") }}</button>
        </div>
      </template>
    </Popup>


  </div>
</template>     

<style lang="scss" scoped>
.upload {
  padding-top: 1rem;
}

h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.options {
  display: flex;
  background: white;
  font-size: 0.875rem;

  > div {
    padding: 1.5rem;

    &:not(:last-child) {
      border-right: 1px solid #E0E0E0;
    }
  }
}

.required-field:after {
  content:" *";
  color: red;
}

.confidentiality, .tags {
  > h5 {
    margin: 2rem 1rem 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background: white;
    padding: 1.5rem;

    label {
      margin-left: 1rem;
      font-weight: bold;
    }

    input {
      margin-right: 0.5rem;
    }
  }

  select {
    margin: 1rem 0
  }

  .warning {
    color: #EF0000;
  }
}

.progress {
  padding: 1.5rem 1.5rem 2rem;
  background: white;
  font-size: 0.875rem;
  border-top: 1px solid #E0E0E0;
}

.buttons {
  margin-top: 1rem;
}

.mdc-select,
.mdc-text-field {
  background-color: white;
  padding-left: 0;
  border-radius: 0;
}

.mdc-select .mdc-select__native-control,
.mdc-select .mdc-floating-label {
  padding-left: 0.5rem;
}

input {
  background-color: #fff;
  padding-top: 0;
  padding-left: 0.1rem;
}

.relative-dates a.chip-trigger {
  display: block;
  padding: 0.5rem;
}

.relative-dates .mdc-chip {
  padding: 0;
}

.buttons button {
  margin-right: 1rem;
}

.buttons button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}

.failed-files-block {
  overflow: auto;
  height: 100px;
  border: 1px solid rgb(118, 118, 118);
  padding: 0.5rem;
}

.failed-files-label {
  margin-bottom: 0.5rem;
}
</style>
