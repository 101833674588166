<script>
import { folderDownloadMixins } from "../../../mixins/folderdownload.js";
import { notificationMixins } from "../../../mixins/notification";
import moment from "moment";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [folderDownloadMixins, notificationMixins],
  props: ["item"],
  components: {
    Popup,
  },
  data() {
    return {
      isDownloading: false,
      isDownloaded: false,
      downloadFolderResults: null,
    };
  },
  emits: ["itemSelected"],
  methods: {
    navigateTo(item) {
      this.$router.push({ name: "browse", params: { id: item.id } });
    },
    downloadHandler() {
      this.isDownloading = true;
    },
    async downloadConfirmed() {
      this.isDownloading = false;
      let results = await this.downloadFolder(this.item);
      this.downloadFolderResults = results.downloadFolder;
      console.log(this.downloadFolderResults);
    },
    downloadQueueClosed() {
      this.isDownloaded = false;
      this.isDownloading = false;
      this.downloadFolderResults = null;
    },
    formatFileSizeInMegabytes(fileSizeInBytes) {
      let fileSizeInMegabytes = fileSizeInBytes / 1024 / 1024;
      return fileSizeInMegabytes.toLocaleString();
    },
    checkboxChange(event) {
      event.stopPropagation();
      this.$emit("itemSelected", this.item.id);
    }
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return "-";
      }
      return moment(String(value)).format("DD/MM/YYYY");
    },
    formatKeywords(keywords) {
      return String(keywords);
    },
    formatTimeTaken(milliseconds) {
      return `${(milliseconds * 1.0) / 1000}s`;
    },
    formatPath(path) {
      return path.replaceAll("||", " > ");
    },
  },
};
</script>

<template>
  <tr class="item-result" v-on:click="navigateTo(item)">
    <td class="select-for-download">
      <label>
        <input type="checkbox" @change="checkboxChange" @click.stop/>
      </label>
    </td>
    <td class="icon">
      <m-icon icon="folder" />
    </td>
    <td>
      <div style="width:100%;overflow:hidden">
        <div style="float:left;width:75%">
          <div class="path">{{ item.path | formatPath }}</div>
          <div v-html="item.name" class="name"></div>
        </div>
        <div style="float:right;width:25%;">
          <button @click.stop="downloadHandler" style="float:right">
            <m-icon
              class="material-icons-outlined"
              icon="file_download"
            ></m-icon>
          </button>
        </div>
      </div>
      <div style="width:100%;overflow:hidden">
        <div class="tag-list">
          <span>{{ item.modified | formatDate }}</span>
          <span> — owner: {{ item.owner }}, steward: {{ item.steward }}</span>
        </div>
        <div class="score">
          <span>{{ item.score }}</span>
        </div>
      </div>
      <div v-if="item.keywords.length" style="width:100%;overflow:hidden">
        <div class="tag-list">
          <span>{{ item.keywords | formatKeywords }}</span>
        </div>
      </div>
      <div v-if="item.metadata.length" style="width:100%;overflow:hidden">
        <div class="metadata-list">
          <div v-for="metadata in item.metadata" :key="metadata.key">
            <span>{{ metadata.key }}</span> -
            <span v-html="metadata.value"></span>
          </div>
        </div>
      </div>
      <div style="width:100%">
        <div
          v-if="item.suspension.is_suspended"
          class="suspension-order pill"
          style="float:left"
          v-html="
            $t('search-results-suspension-order', {
              suspensionOrders: String(item.suspension.orders),
            })
          "
        ></div>
      </div>
      <Popup
        v-if="isDownloading"
        @cancelled="isDownloading = false"
        @confirmed="downloadConfirmed"
      >
        <template v-slot:header>{{ $t("download-folder-title") }}</template>
        <template v-slot:body>
          <div
            class="download-message"
            v-html="$t('download-folder-message', { item: item.name })"
          ></div>
        </template>
      </Popup>
      <Popup
        v-if="downloadFolderResults"
        @confirmed="downloadQueueClosed"
        :allowCancel="false"
      >
        <template v-slot:header>{{
          $t("download-queued-folder-title")
        }}</template>
        <template v-slot:body>
          <p>{{ $t("download-queued-folder-message") }}</p>
          <br />
          <ul>
            <li>
              {{
                $t("download-queued-folder-number-of-files", {
                  numberOfFiles: downloadFolderResults.numberOfFiles.toLocaleString(),
                })
              }}
            </li>
            <li>
              {{
                $t("download-queued-folder-total-size", {
                  totalSize: formatFileSizeInMegabytes(
                    downloadFolderResults.downloadSize
                  ),
                })
              }}
            </li>
            <li>
              {{
                $t("download-queued-folder-number-of-files-excluded", {
                  numberOfFilesExcluded:
                    downloadFolderResults.numberOfFilesNotAdded,
                })
              }}
            </li>
          </ul>
        </template>
      </Popup>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.item-result {
  &:hover {
    cursor: pointer;
    background: #eeeeee;
  }
}

.icon {
  color: #4f4f4f;
  width: 30px;
}

.select-for-download {
  width: 30px;
}

.pill {
  border: none;
  color: black;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 8px 0px 0px 0px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 0.7rem;
}
div.suspension-order {
  background-color: #f09f26;

  ::v-deep em {
    font-weight: bold;
    font-style: normal;
  }
}
div.download-message {
  ::v-deep em {
    font-weight: normal;
    font-style: normal;
  }
}
.project-id {
  background-color: #a0a0a0;
}
div.tag-list {
  color: #4f4f4f;
  float: left;
  margin-top: 0.5rem;

  span {
    font-size: 0.7rem;
  }
}
div.metadata-list {
  color: #4f4f4f;
  float: left;
  margin-top: 0.5rem;

  span {
    font-size: 0.7rem;
  }

  ::v-deep em {
    font-weight: bold;
    font-style: normal;
  }
}
div.path {
  //color: #4f4f4f;
  margin-bottom: 0.4rem;
  font-size: 0.7rem;
}
div.name {
  font-size: 1.1rem;
  color: #01212e;

  ::v-deep em {
    font-weight: bold;
    font-style: normal;
  }
}
div.score {
  display:none;
  color: #4f4f4f;
  float: right;
  margin-top: 0.5rem;

  span {
    font-size: 0.7rem;
  }
}

button {
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 0.3rem;
  padding: 0.25rem 0.25rem;
  background: #dcdcdc;
  height: 35px;
  border-radius: 0.25rem;
  color: #4f4f4f;
  border: solid 1px #4f4f4f;
}
</style>