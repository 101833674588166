<script>
import { apiMixins } from "../../../mixins/api";
import extractAllDroppedFiles from "./extractAllDroppedFiles";

export default {
  mixins: [apiMixins],
  data() {
    return {
      dragOver: 0,
      itemList: [],
      isDisabled: false,
    };
  },
  methods: {
    handleDrop(event) {
      if (this.isDisabled) {
        return;
      }
      return this.withLoader(async () => {
        this.isDisabled = true;
        const files = await extractAllDroppedFiles(event);
        this.$emit("filesReady", files);
      }).catch(console.error);
    },
    handleFileInput(event) {
      if (this.isDisabled) {
        return;
      }
      this.isDisabled = true;
      const files = Array.from(event.target.files)
          .map((file) => ({ file, path: file.webkitRelativePath }));
      this.$emit("filesReady", files);
    },
    triggerFileInput() {
      this.$refs.fileUpload.click();
    },
    handleDragEnter() {
      ++ this.dragOver;
    },
    handleDragLeave() {
      -- this.dragOver;
    },
  },
};
</script>

<template>
  <div class="file-picker">
    <button
        class="file-picker-button"
        @dragenter.prevent="handleDragEnter"
        @dragover.prevent
        @dragleave="handleDragLeave"
        @drop.prevent="handleDrop"
        @click.prevent="triggerFileInput"
        :class="{ active: dragOver > 0 }"
        :disabled="isDisabled"
    >
      <span class="info">{{ $t("filepicker-instructions") }}</span>
      <span class="action">{{ $t("browse-files") }}</span>
    </button>
    <input
        ref="fileUpload"
        multiple
        name="file-upload"
        type="file"
        @change="handleFileInput"
        :disabled="isDisabled"
    />
  </div>
</template>

<style lang="scss" scoped>
input[type=file] {
  display: none;
}

.file-picker-button {
  cursor: pointer;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: white;
  padding: 2rem;
  text-align: left;
  border: 2px solid transparent;

  span {
    display: block;
  }

  .action {
    margin-top: 1.5rem;
    font-weight: bold;
  }

  &.active {
    border-color: black;
  }
}
</style>
