<script>
import Keywords from "./Keywords.vue";
import { mapState } from "vuex";

export default {
  components: {
    Keywords,
  },
  computed: {
    ...mapState({
      permitted: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  },
  mounted() {
    this.$store.dispatch('setBreadcrumbs', [{ to: { name: 'keywords' }, label: this.$t('keywords') }]);
  },
};
</script>

<template>
  <Keywords v-if="permitted" />
  <p v-else>You do not have permission to manage keywords.</p>
</template>
