<script>
import Upload from "./Upload.vue";
import Popup from "../../common/Popup.vue";
import { folderPathCacheMixins } from "../../../mixins/folderPathCache";

export default {
  mixins: [folderPathCacheMixins],
  components: {
    Upload,
    Popup,
  },
  data() {
    return {
      isConfirmLeave: false,
      nextRoute: null,
      uploadState: null,
    };
  },
  computed: {
    isUploading() {
      return this.uploadState === 2; // STATE.UPLOADING from component
    }
  },
  methods: {
    async updateBreadcrumbs() {
      const path = await this.getFolderPathCached(this.$route.params.id);
      await this.$store.dispatch('setBreadcrumbs', [
        { to: { name: 'repositories' }, label: this.$t('repositories') },
        ...path.map((item) => ({ to: { name: 'browse', params: { id: item.id } }, label: item.name })),
        { to: { name: 'upload', params: { id: this.$route.params.id } }, label: this.$t('upload') },
      ]);
    },
    confirmLeave() {
      return window.confirm("Upload in progress. Confirm if you wish to leave the page and stop the upload.")
    },
    confirmStayIfUploadInProgress() {
      return this.isUploading() && !this.confirmStay()
    },
    stayOnThisRoute() {
      this.nextRoute = null;
      this.isConfirmLeave = false;
    },
    gotoNewRoute() {
      this.isConfirmLeave = false;
      this.nextRoute();
    },
    beforeWindowUnload(e) {
      if (this.confirmStayIfUploadInProgress()) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    onUploadStateChanged(state) {
      this.uploadState = state;
    },
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isUploading) {
      next();
    } else {
      this.nextRoute = next;
      this.isConfirmLeave = true;
    }
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {
    this.updateBreadcrumbs();
  },
  watch: {
    '$route.params.id'() {
      this.updateBreadcrumbs();
    }
  }
};
</script>

<template>
  <section>
  <Upload :folderId="$route.params.id" @stateChanged="onUploadStateChanged"/>

  <Popup v-if="isConfirmLeave" @cancelled="stayOnThisRoute" @confirmed="gotoNewRoute">
      <template v-slot:header>Upload in progress</template>
      <template v-slot:body>Click 'Confirm' if you wish to leave and stop the upload.</template>
    </Popup>
  </section>
</template>
