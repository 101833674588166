class DroppedFileExtractor {
  constructor(resolve, reject) {
    this.pendingOperations = 0;
    this.files = [];
    this.resolve = resolve;
    this.reject = reject;
  }

  startOp() {
    ++this.pendingOperations;
  }

  endOp() {
    if ((--this.pendingOperations) === 0) {
      this.resolve(this.files);
    }
  }

  handleFile(item, path) {
    this.startOp();
    item.file((file) => {
      this.files.push({ file, path });
      this.endOp();
    }, this.reject);
  }

  handleDirectory(item, path) {
    this.startOp();

    const that = this;
    const subPath = path + item.name + "/";
    const dirReader = item.createReader();

    let entries = [];

    var readEntries = function () {
      dirReader.readEntries(function(results)  {
          if (results.length) {
              entries.push(...results);
              readEntries();
          } else {
            entries.forEach(entry => that.handleEntry(entry, subPath));
            that.endOp();
          }
      }, that.reject);
    };
    readEntries();
  }

  handleEntry(item, path) {
    this.startOp();
    if (item.isFile) {
      this.handleFile(item, path);
    } else if (item.isDirectory) {
      this.handleDirectory(item, path);
    }
    this.endOp();
  }
}

export default function extractAllDroppedFiles(event) {
  return new Promise((resolve, reject) => {
    const extractor = new DroppedFileExtractor(resolve, reject);
    extractor.startOp();
    Array.from(event.dataTransfer.items).forEach(item => 
      extractor.handleEntry(item.webkitGetAsEntry(), ''));
    extractor.endOp();
  });
}
