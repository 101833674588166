<script>
import { keywordMixins } from "../../../mixins/keyword.js";
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [notificationMixins, keywordMixins],
  emits: [ "modified" ],
  props: {
    keyword: {
      type: Object,
      required: true,
    },
  },
  components: {
    Popup,
  },
  data() {
    return {
      updatedLabel: this.keyword.label,
      updatedComments: this.keyword.comments
    };
  },
  asyncComputed: {
  },
  methods: {
    getTitle() {
      return `${this.keyword.id} - ${this.keyword.label}`;
    },
    getUpdatedTitle() {
      return `${this.keyword.id} - ${this.updatedLabel}`;
    },
    async editKeywordConfirmed() {
      await this.updateKeyword({
        id: this.keyword.id,
        label: this.updatedLabel,
        comments: this.updatedComments
      });
      this.showSuccess(this.$t("keyword-updated", { item: this.getUpdatedTitle() }));
      this.$emit("modified");
      this.$emit("closed", true);
    }
  },
};
</script>

<template>
  <Popup :valid="updatedLabel.length >= 2" @confirmed="editKeywordConfirmed" @cancelled="$emit('closed', false)">
    <template v-slot:header>{{ $t("keyword-edit-title", { item: getTitle() }) }}</template>
      <template v-slot:body>
        <m-text-field v-model="updatedLabel" outlined required maxlength="80">
          <m-floating-label>{{ $t("keyword-edit-label-placeholder") }}</m-floating-label>
        </m-text-field>
        <br/>
        <m-text-field v-model="updatedComments" outlined maxlength="2000">
          <m-floating-label>{{ $t("keyword-edit-comments-placeholder") }}</m-floating-label>
        </m-text-field>
      </template>
  </Popup>
</template>

<style lang="scss" scoped>
  .message {
    margin-bottom: 1.5em;
  }
</style>