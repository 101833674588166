/* eslint-disable no-debugger, no-console */

import { UPLOAD_CHUNK_SIZE } from "../util/constants";
import { NUMBER_OF_FILES_PER_CHUNK } from "../util/constants";
import GcsUpload from "gcs-browser-upload";
import { fileMixins } from "./file";
import { folderMixins } from "./folder";

function cancellable(asyncFn, cancel = (() => null)) {
  let cancelled = false;
  return {
    run: () => asyncFn(() => cancelled).then(result => { return { result, cancelled: cancelled} }),
    cancel: async () => {
      if (!cancelled) {
        cancelled = true;
        await cancel();
      }
    },
  };
}

export const uploadFileMixins = {
  mixins: [fileMixins, folderMixins],
  methods: {
    
    uploadFile({ file, ...options }, itemIndex, progressCallback) {
      let gcs = null;
      let id = null;

      const cancel = async () => {
        if (id !== null) {
          gcs?.cancel();
          await this.deleteFile({ id }).catch(console.error); // not a critical error, but leaves dead files
          id = null;
        }
      };

      return cancellable(async (isCancelled) => {
        try {
          const response = await this.createFile({ ...options, size: file.size, contentType: file.type });
          id = response.id;
          if (isCancelled()) {
            return;
          }
          if (file.size > 0) {
            gcs = new GcsUpload({
              id: response.uploadId,
              url: response.uploadUrl,
              file,
              contentType: file.type,
              chunkSize: UPLOAD_CHUNK_SIZE,
              onChunkUpload: (info) => {
                const fileProgress = itemIndex + (info.uploadedBytes / info.totalBytes);
                if (fileProgress < itemIndex + 1) {
                  progressCallback(fileProgress, file.name);
                }
              },
            });

            await gcs.start();
          }
          if (!isCancelled()) {
            await this.finaliseFile({id});
          }

        } catch (error) {
          // If someone is uploading an entire folder, we don't want to force them to have to deal with these manually!
          if (error.code !== 'DUPLICATE_ITEM') {
            await cancel();
            throw error;
          }
        } finally {
          id = null;
        }
      }, cancel);
    },
    chunkArray(inArray, chunkSize) {
      let returnArray = [];

      for (let i = 0;i<inArray.length;i+=chunkSize) {
        let currentChunk = inArray.slice(i, i + chunkSize);
        returnArray.push(currentChunk);
      }

      return returnArray;
    },
    async uploadFolderPaths(parentItem, paths, tags, metadata) {
      return await this.createPaths(parentItem, paths, tags, metadata);
    },
    uploadChunk(chunk, chunkIndex, progressCallback, errorCallback) {
      let uploadFilePointers = [];
      
      return cancellable(async (isCancelled) => {

        let itemResults = [];
        if (isCancelled()) {
            return { chunkIndex, chunkSize: chunk.length, itemResults }
        }

        // Determine the folder structure for the whole chunk and create that first. Otherwise we end up with
        // concurrency issues where the individual API calls all try and create the missing folders
        // Note we have to run this synchronously
        const unique = (value, index, self) => self.indexOf(value) === index;
        const parentItem = chunk[0].parentItem;
        const tags = chunk[0].tags;
        const metadata = chunk[0].metadata;

        let paths = chunk.map((item) => {
          let parsedPath = item.subPath;
          if (parsedPath && parsedPath.endsWith(item.name)) {
            parsedPath = parsedPath.substring(0, parsedPath.lastIndexOf(item.name));
          }
          return parsedPath;
        })
        .filter(unique)
        .filter(String);
        await this.uploadFolderPaths(parentItem, paths, tags, metadata)
          .catch(error => console.error(error));

        await Promise.all(chunk.map((item, itemIndex) => {

          const overallIndex = (chunkIndex * NUMBER_OF_FILES_PER_CHUNK) + chunkIndex;
          const uploadFile = this.uploadFile(item, overallIndex, progressCallback);        
          uploadFilePointers.push(uploadFile);
          
          const result = uploadFile.run()
            .then(() => {
              progressCallback(overallIndex, "");
              return {itemIndex, success: true};
            })
            .catch(error => {
              errorCallback(item, error);
              return {itemIndex, success: false};
            });

          return result;
        }))
        .then((response) => {
          itemResults = response;
        })
        
        return { chunkIndex, numberOfFilesInChunk: chunk.length, itemResults }
      },
      () => uploadFilePointers.map(uploadFilePointer => uploadFilePointer?.cancel()));
    },
    uploadFiles(items, progressCallback, errorCallback) {
      let currentChunk = null;

      return cancellable(async (isCancelled) => {
        let anySuccess = false;
        
        const itemsInChunks = this.chunkArray(items, NUMBER_OF_FILES_PER_CHUNK);
        const itemProgressCallback = (currentIndex, fileName) => progressCallback(currentIndex, fileName, items.length);
        
        for (let chunkIndex = 0; chunkIndex<itemsInChunks.length; chunkIndex++) {
        
          currentChunk = this.uploadChunk(itemsInChunks[chunkIndex], chunkIndex, itemProgressCallback, errorCallback)
          await currentChunk.run()
            .then(response => {
              anySuccess = anySuccess || response.result.itemResults.filter(r => r?.success).length > 0;
            })
            .catch(error => console.log("failed to process chunk", error));

          if (isCancelled()) {
            return;
          }
        }

        if (isCancelled()) {
          return false;
        }
        
        if (!anySuccess) {
           throw new Error('Failed to upload');
         }
      }, () => currentChunk?.cancel());
    },
  },
};
