<script>
import {retentionMixins} from "../../../mixins/retention.js";
import {notificationMixins} from "../../../mixins/notification";
import Pagination from "../../common/Pagination";
import ResultsFilter from "../../common/ResultsFilter.vue";
import RetentionScheduleItem from "./RetentionScheduleItem.vue";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [notificationMixins, retentionMixins],
  data() {
    return {
      page: 0,
      pageSize: 25,
      filter: "",
      currentSort: "id",
      currentSortDir: "asc",
      isAddNew: false,
      newId: "",
      newCategory: "",
      newTitle: "",
      newPeriod: "",
      newSchedule: null,
      newRegion: "DEFAULT"
    };
  },
  components: {
    Pagination,
    Popup,
    ResultsFilter,
    RetentionScheduleItem
  },
  asyncComputed: {
    retentionSchedules() {
      return this.getRetentionSchedules({
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + ',' + this.currentSortDir,
        // loader MUST NOT be used here, as the async watching behaviour causes an infinite loop
        // See https://github.com/foxbenjaminfox/vue-async-computed/issues/108
        showLoader: false,
        filter: this.filter
      });
    }
  },
  computed: {
    pages() {
      if (!this.retentionSchedules) {
        return 0;
      }
      return Math.ceil(this.retentionSchedules.totalItemCount / this.pageSize);
    },
    sortIcon(){
      if (this.currentSortDir === 'asc') { return "arrow_downward";}
      else return "arrow_upward";
    }
  },
  methods: {
    async addScheduleConfirmed() {
      this.newSchedule = await this.addRetentionSchedule({
        id: this.newId,
        category: this.newCategory,
        title: this.newTitle,
        period: this.newPeriod,
        region: this.newRegion,
      });
      this.showSuccess(this.$t("retention-schedule-added", { item: this.getNewTitle() }));
      this.newId = "";
      this.newTitle = "";
      this.newPeriod = "";
      this.newCategory = "";
      this.newRegion = "DEFAULT";
      this.isAddNew = false;
      this.refresh();
    },
    getNewTitle() {
      return `${this.newId} - ${this.newTitle}`;
    },
    refresh() {
      this.$asyncComputed.retentionSchedules.update();
    },
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    hasRecords() {
      return this.retentionSchedules && this.retentionSchedules.retentionSchedules && this.retentionSchedules.retentionSchedules.length;
    },
    sort(s) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      this.currentSort=s;
    },
  },
};
</script>

<template>
  <div class="shared">
    <div v-if="$asyncComputed.retentionSchedules.updating" class="loading"></div>
    <div v-else-if="$asyncComputed.retentionSchedules.error" class="load-error">Failed to load content</div>
    <div v-else-if="!hasRecords() && !this.filter">
      There are no retention schedules to show
    </div>
    <div v-else-if="!hasRecords() && this.filter">
      There are no retention schedules matching the filter criteria to show
    </div>
    <portal to="actions">
      <ResultsFilter v-if="hasRecords()" @filterChange="setFilter"/>
      <button class="primary" @click="isAddNew = true">
        <m-icon icon="add" />
        {{ $t("retention-schedule-add") }}
      </button>
    </portal>

    <Popup v-if="isAddNew" :valid="newCategory.length >= 2 && newTitle.length >= 2" @cancelled="isAddNew = false" @confirmed="addScheduleConfirmed">
      <template v-slot:header>{{ $t("retention-schedule-add-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="newId" outlined required>
          <m-floating-label>{{ $t("retention-schedule-add-id-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-text-field v-model="newCategory" outlined required>
          <m-floating-label>{{ $t("retention-schedule-add-category-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-text-field v-model="newTitle" outlined required>
          <m-floating-label>{{ $t("retention-schedule-add-title-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-text-field v-model="newPeriod" outlined required>
          <m-floating-label>{{ $t("retention-schedule-add-period-placeholder") }}</m-floating-label>
        </m-text-field>
        <m-select v-model="newRegion" outlined required>
          <option value="default" selected>Default Region</option>
          <option value="svk">SVK Region</option>
        </m-select>
      </template>
    </Popup>

    <table class="listing" v-if="hasRecords()">
      <thead>
        <tr>
          <th class="icon"></th>
          <th class="icon"></th>
          <th class="retention-schedule-id" @click="sort('id')">ID
            <span v-if="currentSort == 'id'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="retention-schedule-category" @click="sort('category')">Category
             <span v-if="currentSort == 'category'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="retention-schedule-title" @click="sort('title')">Title
            <span v-if="currentSort == 'title'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="retention-schedule-period" @click="sort('period')">Period
            <span v-if="currentSort == 'period'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
          <th class="retention-schedule-region" @click="sort('region')">Region
            <span v-if="currentSort == 'region'"><m-icon v-bind:icon="sortIcon" class="sort-icon" /></span>
          </th>
        </tr>
      </thead>
      <template v-for="retentionSchedule in retentionSchedules.retentionSchedules">
        <RetentionScheduleItem :key="retentionSchedule.id" :retentionSchedule="retentionSchedule" v-on:modified="refresh"/>
      </template>
    </table>
    <Pagination v-if="hasRecords()"
      :page="page" 
      :pages="pages" 
      :pageSize="pageSize" 
      :totalRecords="retentionSchedules.totalItemCount" 
      @pageChange="setPage" 
      @pageSizeChange="setPageSize"/>
  </div>
</template>

<style lang="scss" scoped>
.loading, .load-error {
  text-align: center;
  margin-top: 4rem;
}
.sort-icon {
  font-size: 14px;
}
.mdc-select {
  margin-top: 12px;
}
.listing {
  width: 100%;
  font-size: 0.875rem;

  th {
    font-weight: bold;
    color: #5B5B5B;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #EBEBEB;
    }
  }

  th, ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }


}
</style>
