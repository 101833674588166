import { apiMixins } from "./api";

export const searchMixins = {
  mixins: [apiMixins],
  methods: {
    async search({ query, parentId = 0, page = 0, pageSize = 0, sort, showLoader = true }) {
      return this.callAPI("item/search", {
        method: "GET",
        query,
        parentId,
        page,
        pageSize,
        sort,
        showLoader
      });
    },

    async searchExpired({ query, sort, showLoader = true }) {
      return this.callAPI("item/search/expired", {
        method: "GET",
        query,
        sort,
        showLoader
      });
    },
  },
};
