<script>
import { apiMixins } from "../../../mixins/api.js";
import Popup from "../../common/Popup.vue";
import RetentionSchedulePicker from "../../common/RetentionSchedulePicker.vue";
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
    displayRetainHistoric: {
      type: Boolean,
      required: false
    }
  },
  components: { Popup, RetentionSchedulePicker },
  mixins: [apiMixins],
  emits: ["retention-schedule-changed", "retain-historic-changed"],
  data() {
    return {
      availableCategories: [],
      availableRetentionSchedules: [],
      currentRetentionSchedule: null,
      selectedRetentionSchedule: null,
      newRetentionScheduleVisible: false,
      checkRetainHistoric: this.item && this.item.isRetainHistoric,
      parsedItemSchedule: this.item && this.item.defaultRetention && this.item.defaultRetention.replace("#", ""),
    };
  },
  asyncComputed: {},
  computed: {
    formattedRetentionSchedule() {
      if (this.checkRetainHistoric && this.isRetainHistoric()) {
        return this.$t("retention-schedule.keep-historic-title.past-tense");
      }
      if (this.checkRetainHistoric && !this.isRetainHistoric()) {
        return this.$t("retention-schedule.keep-historic-title.future-tense");
      }
      if (this.currentRetentionSchedule) {
        return `${this.currentRetentionSchedule.id} ${this.currentRetentionSchedule.category} ${this.currentRetentionSchedule.title} ${this.currentRetentionSchedule.period}`;
      }
      return this.$t("retention-schedule.no-retention-schedule");
    },
  },
  methods: {
    estimateExpiry() {
      let d = new Date();
      if (!this.currentRetentionSchedule) {
         return null;
      }
      if (this.currentRetentionSchedule.period.endsWith("D")) {
        return moment(d).add("days", this.currentRetentionSchedule.daysUntilExpiry).format("LL");
      } else if (this.currentRetentionSchedule.period.endsWith("M")) {
        return moment(d).add("months", this.currentRetentionSchedule.monthsUntilExpiry).format("LL");
      }
      return moment(d).add("years", this.currentRetentionSchedule.yearsUntilExpiry).format("LL");
    },
    async loadRetentionSchedules() {
      return this.callAPI("item/retention-schedules", { showLoader: false })
        .then((response) => {
          this.availableRetentionSchedules = response.retentionSchedules;
          const categories = this.availableRetentionSchedules
            .map((retentionSchedule) => retentionSchedule.category)
            .sort();
          const uniqueCategories = [...new Set(categories)];
          this.availableCategories = uniqueCategories;
        })
        .then(() => {
          if (this.isStoredItemRetentionScheduleEmpty()) {
            return;
          }
          this.currentRetentionSchedule = this.availableRetentionSchedules.find(
            (r) => r.id === this.parsedItemSchedule
          );
          this.$emit("retention-schedule-changed", this.currentRetentionSchedule);
        });
    },
    isAdmin() {
      return this.$store.state.auth.userData.isAdmin;
    },
    isExpired() {
      return this.item && this.item.isExpired;
    },
    isOwner() {
      return this.item && this.item.owner && this.item.owner.includesMe;
    },
    isSteward() {
      return this.item && this.item.steward && this.item.steward.includesMe;
    },
    isUploader() {
      return this.item && this.item.permissions && this.item.permissions.writeAccess;
    },
    isRetainHistoric() {
      return this.item && this.item.isRetainHistoric;
    },
    onNewRetentionScheduleHandler() {
      this.newRetentionScheduleVisible = true;
      this.selectedRetentionSchedule = null;
    },
    onNewRetentionScheduleCancelledHandler() {
      this.newRetentionScheduleVisible = false;
      this.selectedRetentionSchedule = null;
    },
    onNewRetentionScheduleConfirmedHandler() {
      this.newRetentionScheduleVisible = false;
      this.currentRetentionSchedule = this.selectedRetentionSchedule;
      this.$emit("retention-schedule-changed", this.currentRetentionSchedule);
    },
    onRetainHistoricChangedHandler() {
      this.$emit("retain-historic-changed", this.checkRetainHistoric);
    },
    onNewRetentionScheduleChangedHandler(selectedRetentionSchedule) {
      this.selectedRetentionSchedule = selectedRetentionSchedule;
    },
    isStoredItemRetentionScheduleEmpty() {
      return (
        !this.item || !this.item.defaultRetention || this.item.defaultRetention === "N/A"
      );
    },
    isNewRetentionScheduleAvailable() {
      return this.isSteward() || this.isOwner() || this.isAdmin();
    },
    isRetainHistoricAvailable() {
      return (
          this.isRetainHistoric() ||
          (!this.isRetainHistoric() && this.isExpired() && this.isSteward()) ||
          (!this.isRetainHistoric() && this.isExpired() && this.isOwner()) ||
          (!this.isRetainHistoric() && this.isAdmin())
        );
    },
    hasRetentionScheduleChanged() {
      if (!this.item && this.currentRetentionSchedule) {
        return true;
      }
      if (this.item && this.item.defaultRetention) {
        return true;
      }
      if (this.item && this.currentRetentionSchedule) {
        return this.currentRetentionSchedule.id !== this.parsedItemSchedule;
      }
      return false;
    },
    shouldShowNewRetentionSchedule() {
      return (
        (!this.item || this.isSteward() || this.isOwner() || this.isAdmin() || this.isUploader()) &&
        !this.checkRetainHistoric
      );
    },
    showRetainHistoric() {
      return (
          this.item && (
        this.isRetainHistoric() ||
        (!this.isRetainHistoric() && this.isExpired() && this.isSteward() && this.displayRetainHistoric) ||
        (!this.isRetainHistoric() && this.isExpired() && this.isOwner() && this.displayRetainHistoric) ||
        (!this.isRetainHistoric() && this.isAdmin() && this.displayRetainHistoric))
      );
    },
  },
  mounted() {
    this.loadRetentionSchedules();
  },
};
</script>

<template>
  <section class="expiry">
    <h5>
      {{ $t("retention-schedule.header") }} - {{ this.formattedRetentionSchedule }}
    </h5>
    <section>
      <div class="retention-block">
        {{ $t(this.item ? "retention-schedule.keep-until.file" : "retention-schedule.keep-until.upload") }}
      </div>
      <div class="retention-block">
        {{ $t("retention-schedule.policies") }}
      </div>
      <div class="retention-block">
        <button
          class="primary"
          @click="onNewRetentionScheduleHandler"
          :disabled="!shouldShowNewRetentionSchedule() || (item && item.trashRepository)"
        >
          <m-icon icon="event" />
          {{ $t("retention-schedule.new") }}
        </button>
      </div>
      <div class="retention-block" v-if="showRetainHistoric()">
        <m-checkbox
          id="retain-historic-checkbox"
          v-model="checkRetainHistoric"
          style="padding-top:0rem;padding-left:0rem"
          @change="onRetainHistoricChangedHandler"
          :disabled="item && item.trashRepository"
        />
        <label for="retain-historic-checkbox">{{
          $t("retention-schedule.keep-historic-label")
        }}</label>
      </div>

      <div class="warning" v-if="hasRetentionScheduleChanged() && !checkRetainHistoric">
        <p>
          {{
            $t("retention-schedule.estimated-expiry.upload", {
              expiry: estimateExpiry(),
            })
          }}
          {{ $t("retention-schedule.steward-warning") }}
        </p>
      </div>
    </section>

    <Popup
      v-if="newRetentionScheduleVisible"
      @cancelled="onNewRetentionScheduleCancelledHandler"
      @confirmed="onNewRetentionScheduleConfirmedHandler"
      :defaultButtons="false"
    >
      <template v-slot:header>{{ $t("retention-schedule-picker.header") }}</template>
      <template v-slot:body>
        <RetentionSchedulePicker
          id="retention-schedule-picker"
          :item="item"
          :availableRetentionSchedules="availableRetentionSchedules"
          :currentRetentionSchedule="currentRetentionSchedule"
          @retention-schedule-changed="onNewRetentionScheduleChangedHandler"
        />
      </template>
      <template v-slot:footer>
          <button type="button" 
            @click.stop="onNewRetentionScheduleCancelledHandler">
            <slot name="cancelLabel">{{ $t("cancel") }}</slot>
          </button>
          <button class="primary" type="submit" 
          :disabled="!selectedRetentionSchedule && hasRetentionScheduleChanged" 
            @click.prevent="onNewRetentionScheduleConfirmedHandler">
            <slot name="confirmLabel">{{ $t("ok") }}</slot>
          </button>
      </template>
    </Popup>
  </section>
</template>

<style lang="scss" scoped>
h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.expiry {
  > h5 {
    margin: 2rem 1rem 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background: white;
    padding: 1.5rem;

    label {
      margin-left: 1rem;
      font-weight: bold;
    }

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }

    button {
      margin-top: 1rem;
      //margin-left: 2rem;
      width: 275px;
      background: transparent;
      border: 1px solid #1275cc;
      color: #1275cc;
      border-radius: 4px;
      padding: 0.75rem 1rem;
      font-weight: bold;
      cursor: pointer;
      line-height: 1.6rem;

      &:disabled {
        cursor: default;
        opacity: 0.2;
      }

      &.primary {
        background: #1275cc;
        border-color: transparent;
        color: white;
      }

      > i {
        vertical-align: -0.35rem;
        padding-right: 0.25rem;
      }
    }

    .retention-block {
      margin-bottom: 1rem;
    }
  }

  .warning {
    color: #ef0000;
    margin-top: 1rem;
  }

  footer {
  padding: 0 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

footer > * {
  margin-left: 1.5rem;
}

  footer button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
}
</style>
