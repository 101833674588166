import { apiMixins } from "./api";

const EXPIRY_ENDPOINT = "item/expired";
const FOLDER_PATH_ENDPOINT = "item/path";

export const expiryActions = {
  mixins: [apiMixins],
  methods: {
    async getExpiredItems({ query, sort, showLoader = false }) {
      return this.callAPI(EXPIRY_ENDPOINT, {
        method: "GET",
        query,
        sort,
        showLoader,
      });
    },
    async getExpiredItemSummary(folderPath) {
      return this.callAPI(EXPIRY_ENDPOINT + "/summary", {
        method: "GET",
        query: { folderPath: folderPath },
        showLoader: false,
      });
    },
    async getIdForFolderPath(folderPath) {
      return this.callAPI(FOLDER_PATH_ENDPOINT, {
        method: "GET",
        query: { folderPath: folderPath },
        showLoader: false,
      });
    },
    async deleteExpiredItems(folderPath) {
      return this.callAPI(`${EXPIRY_ENDPOINT}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ folderPath: folderPath }),
        showLoader: true,
      });
    },
    async renewExpiredItems(folderPath) {
      return this.callAPI(`${EXPIRY_ENDPOINT}/renew`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ folderPath }),
        showLoader: true,
      });
    },
    async changeRetentionForExpiredItems(folderPath, retentionSchedule) {
      return this.callAPI(`${EXPIRY_ENDPOINT}/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          folderPath,
          newRetentionSchedule: retentionSchedule,
        }),
        showLoader: true,
      });
    },
    async retainExpiredItemsForHistoricReasons(folderPath) {
      return this.callAPI(`${EXPIRY_ENDPOINT}/retain`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ folderPath: folderPath }),
        showLoader: true,
      });
    },
    async refreshExpiredDocuments() {
      return this.callAPI(`${EXPIRY_ENDPOINT}/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        showLoader: true,
      });
    },
  },
};