<script>
import Browser from "./Browser.vue";
import { folderPathCacheMixins } from "../../../mixins/folderPathCache";

export default {
  mixins: [folderPathCacheMixins],
  components: {
    Browser,
  },
  methods: {
    async updateBreadcrumbs() {
      const path = await this.getFolderPathCached(this.$route.params.id);
      const breadcrumbs = this.$store.state.crumbs;
      const initialCrumb = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs[0] : { to: { name: 'all' }, label: this.$t('nav.all') };
      await this.$store.dispatch('setBreadcrumbs', [
        initialCrumb,
        ...path.map((item) => ({ to: { name: 'browse', params: { id: item.id } }, label: item.name })),
      ]);
    },
  },
  mounted() {
    this.updateBreadcrumbs();
  },
  watch: {
    '$route.params.id'() {
      this.updateBreadcrumbs();
    },
  },
};
</script>

<template>
  <Browser :folderId="$route.params.id" :flag="$route.params.flag" />
</template>