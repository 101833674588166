<script>
import { repositoryMixins } from "../../../mixins/repository.js";

export default {
  mixins: [repositoryMixins],
 
  methods: {
    async getRepositoryId() {
      return this.getRepositoryByName({ showLoader: false, name: "Trash" });
    }
  },
  async mounted() {
    const id = await this.getRepositoryId();
    this.$router.push({ name: "browse", params: { id } });
  }
};
</script>

<template><div></div></template>