<script>
import FileOperations from "./FileOperations.vue";
import { fileMixins } from "../../../mixins/file.js";
import ItemSharing from "../sharing-popup/ItemSharing.vue";
import {formatISODate} from "../../../util/date";
import {prettyPrintFileSize} from "../../../util/file";

function performDownload(url, filename) {
  const a = document.createElement("a");
  a.download = filename;
  a.target = "_blank";
  a.href = url;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export default {
  mixins: [fileMixins],
  props: ["item"],
  components: {
    FileOperations,
    ItemSharing,
  },
  data() {
    return {
      isOptionsVisible: false,
      isSharing: false,
    }
  },
  methods: {
     toFileProperties(){
       this.$router.push({ path: "/properties/" + this.item.id,   params: { item: this.item }}
      );
    },
    toggleOptions() {
      if (this.item.permissions.writeAccess || this.item.permissions.deleteAccess) {
        this.isOptionsVisible = !this.isOptionsVisible;
      }
    },
    async downloadHandler() {
      const url = await this.getFileDownloadUrl(this.item);
      performDownload(url, this.item.name);
    },
    sharingClosed(changed) {
      this.isSharing = false;
      if (changed) {
        this.$emit('modified');
      }
    },
    formatCreatedOnDate(createdOnISODate){
      return formatISODate(createdOnISODate);
    }
  },
  filters: {
    prettyBytes(value) {
      return prettyPrintFileSize(value);
    },
  },
};
</script>

<template>
  <tbody>
  <tr class="item" @click.prevent="$emit('selected')" :class="{ opened: isOptionsVisible }">
    <td class="icon">
      <m-icon class="material-icons-outlined" icon="insert_drive_file" />
      <span class="secret-dot" v-if="item.isSecret"></span>
    </td>
    <td class="name">
      <a href="#" @click.prevent="toFileProperties">{{ item.name }}</a>
      <div class="tag-list" v-if="item.tags && item.tags.length">
            <span>{{ item.tags.join(", ") }}</span>
          </div>
    </td>
    <td class="owner">{{ item.owner.name }}</td>
    <td>{{ formatCreatedOnDate(item.created) }}</td>
    <td v-if="item.expire">{{ item.expire }}</td>
    <td v-if="!item.expire">N/A</td>
    <td v-if="item.existInStorage"> {{ item.size | prettyBytes}}</td>
    <td v-if="!item.existInStorage"><strong>Upload not complete</strong></td>
    <td class="actions" @click.stop>
      <span class="secret-status" v-if="item.isSecret">{{ $t("secret") }}</span>
      <span class="suspended-status" v-if="item.suspended">{{ $t("suspended") }}</span>
      <button v-if="item.existInStorage && item.permissions.readAccess" @click.prevent="downloadHandler">{{ $t("download") }}</button>
      <button v-if="item.permissions.writeAccess" @click.stop="isSharing = true">{{ $t("share") }}</button>
      <ItemSharing v-if="isSharing" :item="item" icon="insert_drive_file" @closed="sharingClosed" />
    </td>
    <td class="options" @click.stop="toggleOptions">
      <a v-if="item.permissions.writeAccess || item.permissions.deleteAccess" href="#" @click.prevent>
        <m-icon icon="settings" />
      </a>
    </td>
  </tr>
  <tr class="options-bar" v-if="isOptionsVisible">
    <td colspan="9">
      <FileOperations :item="item" @modified="$emit('modified')" :isParentFolderGroupRegister="true" />
    </td>
  </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.item {
  .actions {
    text-align: right;
    white-space: nowrap;

    .secret-status, .suspended-status {
      margin: -0.5rem 0 -0.5rem 1rem;
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
      background: #F09F26;
    }
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

    .options {
      color: #4F4F4F;
    }
  }

  .icon {
    color: #4F4F4F;
    display: flex;
    align-items: center;
  }

  .secret-dot {
    display: inline-block;
    background: #F09F26;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  .options {
    color: #CACACA;

    &:hover {
      color: #4F4F4F;
    }
    text-align: center;
  }

  td {
    font-size: 0.8rem;
  }

  div.tag-list {
    margin-top: 0.5rem;
  }
  div.tag-list span {
    font-size: 0.7rem;
    font-style: italic;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}

tr.options-bar {
  background: #EBEBEB;
}

</style>
