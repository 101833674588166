import {apiMixins} from "./api";

const RETENTION_ENDPOINT = "retention";

export const retentionMixins = {
  mixins: [apiMixins],
  methods: {
    async getRetentionSchedules({ page = 0, pageSize = 0, sort, showLoader = true, filter="" } = {}) {
      return await this.callAPI(RETENTION_ENDPOINT, {
        method: "GET",
        query: {page, pageSize, sort, filter},
        showLoader,
      });
    },
    async activateRetentionSchedule(id) {
        return await this.callAPI(`${RETENTION_ENDPOINT}/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "isActive": true }),
        });
    },
    async deactivateRetentionSchedule(id) {
        return await this.callAPI(`${RETENTION_ENDPOINT}/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "isActive": false }),
        });
    },
    async updateRetentionSchedule({ id = "", title = "", category = "", period = "", region = ""} = {}) {
        return await this.callAPI(`${RETENTION_ENDPOINT}/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "title": title, "category": category, "period": period, "region": region }),
        });
    },
    async addRetentionSchedule({ id = "", title = "", category = "", period = "", region = "DEFAULT"} = {}) {
        return await this.callAPI(RETENTION_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "id": id, "title": title, "category": category, "period": period, "region": region }),
        });
    }
  },
};
