import { apiMixins } from "./api";

const TRASH_ENDPOINT = "trash";

export const trashMixins = {
  mixins: [apiMixins],
  methods: {
    async emptyTrash() {
        await this.callAPI(TRASH_ENDPOINT + "/empty", {
            method: "POST",
        });
    }
  }
}